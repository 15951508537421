import { useMutation, useQuery } from '@tanstack/react-query'
import { addQueryData, removeQueryData, updateQueryData } from '../../libs/react-query'
import { createTarget, deleteTarget, getTargets, updateTarget } from './targets.io'

export const queryIds = {
  useTargets: (organizationId, projectId) => [
    'organizations',
    organizationId,
    'projects',
    projectId,
    'targets',
  ],
}

export const useTargets = (organizationId, projectId, options = {}) =>
  useQuery(
    queryIds.useTargets(organizationId, projectId),
    () => getTargets(organizationId, projectId),
    options,
  )

export const useCreateTarget = (organizationId, projectId, options = {}) =>
  useMutation(
    ({ name, branch, platform, ueVersion }) =>
      createTarget(organizationId, projectId, {
        name,
        branch,
        platform,
        ueVersion,
      }),
    {
      ...options,
      onSuccess: (target) => {
        addQueryData(queryIds.useTargets(organizationId, projectId), target)
        options?.onSuccess?.(target)
      },
    },
  )

export const useUpdateTarget = (organizationId, projectId, targetId, options = {}) =>
  useMutation((payload) => updateTarget(organizationId, projectId, targetId, payload), {
    ...options,
    onSuccess: (target) => {
      updateQueryData(queryIds.useTargets(organizationId, projectId), target)
      options?.onSuccess?.(target)
    },
  })

export const useDeleteTarget = (organizationId, projectId, targetId, options = {}) =>
  useMutation(() => deleteTarget(organizationId, projectId, targetId), {
    ...options,
    onSuccess: (target) => {
      removeQueryData(queryIds.useTargets(organizationId, projectId), target)
      options?.onSuccess?.(target)
    },
  })

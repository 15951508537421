import Pusher from 'pusher-js/with-encryption'
import { apiAxios } from './axios'
import { pusherConfig } from '../config'

export const pusher = new Pusher(pusherConfig.key, {
  cluster: pusherConfig.cluster,
  forceTLS: true,
  authorizer: (channel, _) => {
    return {
      authorize: (socketId, callback) =>
        apiAxios
          .post('/pusher/auth', null, {
            params: {
              socket_id: socketId,
              channel_name: channel.name,
            },
          })
          .then((res) => callback(null, res))
          .catch((err) => callback(err, null)),
    }
  },
})

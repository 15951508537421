import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import { Router } from '../../components/routers/Router'
import { ProjectDetails } from './ProjectDetails'
import { ProjectSCM } from './ProjectSCM'
import { Alert } from '../../components/Alerts'
import { Link } from '../../components/link/Link'
import { Spinner } from '../../components/Reusable'
import { DashboardPage } from '../../components/dashboard-page/DashboardPage'
import { useProject } from '../../services/projects/projects.queries'

export const WithProject = ({ routes, ...rest }) => {
  const { organizationId, projectId } = useParams()

  const { data: project, isLoading, error, remove } = useProject(organizationId, projectId)

  useEffect(() => remove, [])

  if (isLoading) {
    return <Spinner isLoading={isLoading} />
  }

  if (error) {
    return <Alert show={!!error} message={error?.message} severity="error" />
  }

  return <Router routes={routes} project={project} {...rest} />
}

export const ProjectPage = (props) => {
  const { organization, project } = props

  return (
    <DashboardPage
      title={
        <Link
          to={`/organizations/${organization.id}`}
          icon={<ChevronLeft />}
          label="Organization Page"
        />
      }
    >
      <ProjectDetails project={project} />
      <Box marginBottom={4} />
      <ProjectSCM project={project} />
    </DashboardPage>
  )
}

ProjectPage.propTypes = {
  organization: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
}

import { useState } from 'react'
import { lighten, makeStyles } from '@material-ui/core/styles'
import ArrowDropUpIcon from '@material-ui/icons/ArrowRight'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { useAuth } from '../../hooks/useAuth'
import { Avatar } from '../avatar/Avatar'
import { NavLink } from '../routers/NavLink'
import { useNotifications } from '../../hooks/useNotifications'
import { signOut } from '../../services/auth.io'

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    padding: 15,
    background: palette.common.black,
  },
  button: {
    padding: spacing(2),
    justifyContent: 'unset',
    borderRadius: 40,
  },
  avatar: {
    marginRight: spacing(2),
  },
  name: {
    textAlign: 'left',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  dropUpIcon: {
    marginLeft: 'auto',
  },
  menuPaper: {
    background: lighten(palette.common.black, 0.1),
  },
  menuList: {
    padding: 0,
  },
  menuItem: {
    minHeight: 75,
    padding: `0 ${spacing(4)}px`,
  },
}))

export const ProfileMenu = () => {
  const [user] = useAuth()
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const { showSuccess, showError } = useNotifications()

  const handleClick = (e) => setAnchorEl(e.currentTarget)
  const handleClose = () => setAnchorEl(null)

  const handleLogout = () => {
    signOut()
      .then(() => {
        showSuccess('Successfully logged out')
        handleClose()
      })
      .catch(() => showError('Error logging out'))
  }

  return (
    <div className={classes.root}>
      <Button className={classes.button} onClick={handleClick} fullWidth>
        <Avatar user={user} className={classes.avatar} size={40} />
        <div className={classes.name}>{user.firstName || user.lastName || user.email}</div>
        <ArrowDropUpIcon className={classes.dropUpIcon} />
      </Button>
      <Menu
        classes={{
          paper: classes.menuPaper,
          list: classes.menuList,
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          component={NavLink}
          to="/profile"
          className={classes.menuItem}
          onClick={handleClose}
          button
        >
          My Profile
        </MenuItem>
        <MenuItem
          component={NavLink}
          to="/scm-accounts"
          className={classes.menuItem}
          onClick={handleClose}
          button
        >
          SCM Account
        </MenuItem>
        <MenuItem onClick={handleLogout} className={classes.menuItem}>
          Logout
        </MenuItem>
      </Menu>
    </div>
  )
}

import { useState } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { ErrorText } from '../../components/Reusable'
import { editProfileSchema } from '../../components/validations'
import { useAuth } from '../../hooks/useAuth'
import { useNotifications } from '../../hooks/useNotifications'
import { updateUser, updateEmail } from '../../services/auth.io'
import { LoginForm } from '../login/Form'

export const EditProfileModal = (props) => {
  const { isOpen, onClose, onSave } = props

  const [user, setUser] = useAuth()
  const { showSuccess, showError } = useNotifications()
  const [showLoginModal, setShowLoginModal] = useState(false)
  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting, isValid, errors },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(editProfileSchema),
    shouldFocusError: false,
    defaultValues: {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
    },
  })

  const handleCloseReauthenticate = () => setShowLoginModal(false)

  const handleUpdateUser = ({ firstName, lastName, email }) => {
    Promise.resolve()
      .then(() => user.email !== email && updateEmail(email))
      .then(() => updateUser({ firstName, lastName }))
      .then(setUser)
      .then((updatedUser) => {
        // to get the latest if we open the modal again
        reset({ firstName, lastName, email })

        showSuccess('User successfully updated.')
        onSave(updatedUser)
      })
      .catch(({ code, message }) => {
        // user needs to reauthenticate, show login form in popup
        if (code === 'auth/requires-recent-login') {
          setShowLoginModal(true)
        } else {
          showError(message)
        }
      })
  }

  return (
    <>
      <Dialog open={showLoginModal} onClose={handleCloseReauthenticate}>
        <DialogTitle>Reauthenticate</DialogTitle>

        <DialogContent>
          <Typography gutterBottom>
            Before changing your email, reauthenticate with your current one.
          </Typography>
          <br />
          <LoginForm onLogin={handleCloseReauthenticate} />
        </DialogContent>

        <DialogActions>
          <Button
            fullWidth
            variant="contained"
            style={{
              margin: '-8px 16px 8px 16px',
            }}
            onClick={handleCloseReauthenticate}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isOpen} onClose={onClose} fullWidth>
        <DialogTitle>Edit your Profile</DialogTitle>

        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                {...register('firstName')}
                name="firstName"
                label="First Name"
                variant="outlined"
                fullWidth
                autoFocus
              />
              <ErrorText>{errors.firstName?.message}</ErrorText>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                {...register('lastName')}
                name="lastName"
                label="Last Name"
                variant="outlined"
                fullWidth
              />
              <ErrorText>{errors.lastName?.message}</ErrorText>
            </Grid>

            <Grid item xs={12}>
              <TextField
                {...register('email')}
                name="email"
                type="email"
                label="Email"
                variant="outlined"
                fullWidth
              />
              <ErrorText>{errors.email?.message}</ErrorText>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose}>Close</Button>
          <Button
            color="primary"
            disabled={!isValid || isSubmitting}
            onClick={handleSubmit(handleUpdateUser)}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

EditProfileModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

import { Home } from '../home/Home'
import { ProfilePage } from '../profile/ProfilePage'
import { ProjectPage, WithProject } from '../project/ProjectPage'
import { OrgProjectList } from '../organization/org-project-list/OrgProjectList'
import { OrganizationPage, WithOrganization } from '../organization/OrganizationPage'
import { OrganizationList } from '../organization-list/OrganizationList'
import { BuildPage, WithBuild } from '../build/BuildPage'
import { ScmAccountsPage } from '../scm-accounts/ScmAccountsPage'
import { TargetList } from '../target-list/TargetList'
import { BuildList } from '../build-list/BuildList'
import { BillingPage } from '../billing/BillingPage'

export const routes = [
  {
    element: Home,
    path: '/',
    exact: true,
  },
  {
    element: ProfilePage,
    path: '/profile',
    exact: true,
  },
  {
    element: OrganizationList,
    path: '/organizations',
    exact: true,
  },
  {
    element: (props) => (
      <WithOrganization
        {...props}
        routes={[
          {
            element: OrganizationPage,
            path: '/',
            exact: false,
          },
          {
            element: OrgProjectList,
            path: '/projects',
            exact: true,
          },
          {
            element: BillingPage,
            path: '/billing',
            exact: true,
          },
          {
            element: (props) => (
              <WithProject
                {...props}
                routes={[
                  {
                    element: ProjectPage,
                    path: '/',
                    exact: true,
                  },
                  {
                    element: TargetList,
                    path: '/targets',
                    exact: true,
                  },
                  {
                    element: BuildList,
                    path: '/builds',
                    exact: true,
                  },
                  {
                    element: (props) => (
                      <WithBuild
                        {...props}
                        routes={[
                          {
                            element: BuildPage,
                            path: '/',
                            exact: true,
                          },
                        ]}
                      />
                    ),
                    path: '/builds/:buildId/*',
                    exact: false,
                  },
                ]}
              />
            ),
            path: '/projects/:projectId/*',
            exact: false,
          },
        ]}
      />
    ),
    path: '/organizations/:organizationId/*',
    exact: false,
  },
  {
    element: ScmAccountsPage,
    path: '/scm-accounts',
    exact: true,
  },
]

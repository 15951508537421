import { useCallback, useState } from 'react'
import NewWindow from 'react-new-window'
import upperFirst from 'lodash/upperFirst'
import { appHost, githubApp } from '../../config'
import { useEventListener } from '../../hooks/useEventListener'
import { useNotifications } from '../../hooks/useNotifications'
import { GithubButton } from '../buttons/GithubButton'
import { useCreateAccessToken } from '../../services/github/github.queries'
import { genRandomString } from '../../utils'

export const getGithubAuthorizationUrl = (state) => {
  return `https://github.com/apps/${githubApp}/installations/new?state=${state}`
  // return `${githubBaseUrl}/login/oauth/authorize?client_id=${githubClientId}&state=${state}&scope=${scope}`
}

// To view authorization information:
// https://github.com/settings/connections/applications/:client_id
export const GithubAuthorize = (props) => {
  const [initialState] = useState(genRandomString())
  const [window, setWindow] = useState(null)
  const [isRequestOAuth, setIsRequestOAuth] = useState(false)
  const { showSuccess, showError } = useNotifications()

  const authorizeUrl = getGithubAuthorizationUrl(initialState)

  const { mutateAsync: createAccessToken } = useCreateAccessToken({
    onSuccess: (scm) => showSuccess(`${upperFirst(scm.provider)} successfully connected`),
    onError: ({ message }) => showError(message),
  })

  const handleOauthResult = useCallback(
    async ({ data, origin, isTrusted }) => {
      console.log({ isTrusted, origin, data })
      // ensure message is sent from us
      if (!isTrusted || origin !== appHost) {
        return
      }

      // code, installation_id, provider=github, setup_action=install
      // ensure Github's returned state matches our initial
      const { code, state, installation_id, setup_action, provider } = data
      if (!code || !installation_id || setup_action !== 'install' || state !== initialState) {
        return
      }

      await createAccessToken({ code, provider, installation_id })
    },
    [initialState],
  )
  useEventListener('message', handleOauthResult)

  const handleOnClick = (e, allowPrivateRepos) => {
    setIsRequestOAuth(true)
    window?.focus()
  }

  const handleOnBlock = () => {
    setIsRequestOAuth(false)
    showError('Error opening OAuth window. Ensure no popup blocker is preventing it.')
  }

  const handleOnUnload = () => {
    setIsRequestOAuth(false)
    setWindow(null)
  }

  return (
    <>
      <GithubButton title="Authorize" onClick={handleOnClick} />

      {isRequestOAuth && (
        <NewWindow
          url={authorizeUrl}
          copyStyles={false}
          onOpen={setWindow}
          onUnload={handleOnUnload}
          onBlock={handleOnBlock}
          center="parent"
          {...props}
        />
      )}
    </>
  )
}

import PropTypes from 'prop-types'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { CopyToClipboard } from '../../components/copy-to-clipboard/CopyToClipboard'
import { useBuildUsages } from '../../services/builds/builds.queries'

export const ProjectDetails = (props) => {
  const { project } = props

  const formatDate = (date) => format(parseISO(date), 'LLLL do, yyyy')

  const { data: usages } = useBuildUsages(project.organizationId, project.id)
  console.log({ usages })

  return (
    <Card>
      <CardHeader title="Project Details" />

      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Typography variant="caption">Name</Typography>
            <Typography variant="body1">{project.name}</Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography variant="caption">Created At</Typography>
            <Typography variant="body1">{formatDate(project.createdAt)}</Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography variant="caption">Project ID</Typography>
            <Typography variant="body1">
              {project.id}
              <CopyToClipboard text={project.id} />
            </Typography>
          </Grid>

          {project.updatedAt !== project.createdAt && (
            <Grid item xs={12} sm={6}>
              <Typography variant="caption">Updated At</Typography>
              <Typography variant="body1">{formatDate(project.updatedAt)}</Typography>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  )
}

ProjectDetails.propTypes = {
  project: PropTypes.object.isRequired,
}

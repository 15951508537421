import PropTypes from 'prop-types'
import { Subscriptions } from '../../../../components/subscription/Subscriptions'
import {
  useOrganizationPaymentMethods,
  useOrganizationSubscription,
  usePlans,
} from '../../../../services/billing/billing.queries'
import { Spinner } from '../../../../components/Reusable'

export const SubscriptionsTab = (props) => {
  const { organizationId } = props
  const { data: plans = [], isFetching: isFetchingPlans } = usePlans()
  const { data: paymentMethods, isFetching: isFetchingPaymentMethods } =
    useOrganizationPaymentMethods(organizationId, { retry: false })
  const { data, isFetching: isFetchingSubscription } = useOrganizationSubscription(organizationId, {
    retry: false,
  })

  if (isFetchingPlans || isFetchingSubscription || isFetchingPaymentMethods) {
    return <Spinner isLoading />
  }

  return (
    <Subscriptions
      organizationId={organizationId}
      subscription={data}
      plans={plans}
      paymentMethods={paymentMethods}
    />
  )
}

SubscriptionsTab.propTypes = {
  organizationId: PropTypes.string.isRequired,
}

import { useState } from 'react'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { useAuth } from '../../hooks/useAuth'
import { Avatar } from '../../components/avatar/Avatar'
import { DashboardPage } from '../../components/dashboard-page/DashboardPage'
import { EditPasswordModal } from './EditPasswordModal'
import { EditProfileModal } from './EditProfileModal'
import { ApiKey } from './ApiKey'

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  avatar: {
    height: 250,
    width: 250,
    margin: 'auto',
    marginBottom: spacing(3),
    [breakpoints.down(breakpoints.values.md)]: {
      height: 150,
      width: 150,
    },
    [breakpoints.down(breakpoints.values.sm)]: {
      height: 75,
      width: 75,
    },
  },
  apiKeyLabel: {
    // compensate for icons
    marginTop: spacing(-1),
  },
}))

export const ProfilePage = () => {
  const [user] = useAuth()
  const classes = useStyles()
  const [isEditProfileOpen, setIsEditProfileOpen] = useState(false)
  const [isEditPasswordOpen, setIsEditPasswordOpen] = useState(false)

  const formatDate = (date) => format(parseISO(date), 'LLLL do, yyyy')

  const handleCloseEditProfile = () => setIsEditProfileOpen(false)
  const handleCloseEditPassword = () => setIsEditPasswordOpen(false)

  return (
    <DashboardPage>
      <Card>
        <CardHeader
          title="My Profile"
          action={
            <CardActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() => setIsEditPasswordOpen(true)}
              >
                Edit Password
              </Button>

              <Button
                color="primary"
                variant="contained"
                onClick={() => setIsEditProfileOpen(true)}
              >
                Edit Profile
              </Button>
            </CardActions>
          }
        />

        <CardContent>
          <Grid container item spacing={3}>
            <Grid container item spacing={3} xs={12} sm={6}>
              <Avatar user={user} size={250} className={classes.avatar} />
            </Grid>

            <Grid container item xs={12} sm={6} alignItems="flex-start">
              <Grid item xs={12}>
                <Typography variant="caption">Name</Typography>
                <Typography variant="body1" gutterBottom>
                  {user.firstName} {user.lastName}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="caption">Email</Typography>
                <Typography variant="body1" gutterBottom>
                  {user.email}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="caption">Unreal Member Since</Typography>
                <Typography variant="body1" gutterBottom>
                  {formatDate(user.createdAt)}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="caption">API Key</Typography>
                <Typography variant="body1" gutterBottom className={classes.apiKeyLabel}>
                  <ApiKey />
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <EditProfileModal
        isOpen={isEditProfileOpen}
        onSave={handleCloseEditProfile}
        onClose={handleCloseEditProfile}
      />

      <EditPasswordModal
        isOpen={isEditPasswordOpen}
        onSave={handleCloseEditPassword}
        onClose={handleCloseEditPassword}
      />
    </DashboardPage>
  )
}

import { useMemo } from 'react'
import PropTypes from 'prop-types'
import sortBy from 'lodash/sortBy'
import * as Yup from 'yup'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import FormHelperText from '@material-ui/core/FormHelperText'
import { useNotifications } from '../../hooks/useNotifications'
import { ErrorText } from '../../components/Reusable'
import { useBranches } from '../../services/github/github.queries'
import { useCreateTarget } from '../../services/targets/targets.queries'
import { Platforms } from '../../constants/Platforms'
import { useUEVersions } from '../../services/ue/ue.queries'

const schema = Yup.object({
  name: Yup.string().required().label('Name'),
  platform: Yup.string().required().label('Platform'),
  branch: Yup.string().required().label('Branch'),
  ueVersion: Yup.string().required().label('UE Version'),
})

export const CreateTargetModal = (props) => {
  const { organization, project, isOpen, onClose } = props

  const {
    data: ueVersions,
    isLoading: ueVersionsLoading,
    error: ueVersionsError,
  } = useUEVersions({
    select: (data) => sortBy(data, 'version'),
  })

  const owner = project?.scm?.repository?.owner?.login
  const repo = project?.scm?.repository?.name
  const {
    data: branches = [],
    isInitialLoading: branchesLoading,
    error: branchesError,
  } = useBranches(
    owner,
    repo,
    { page: 1, per_page: 100 },
    {
      enabled: Boolean(owner && repo),
    },
  )

  const { showSuccess, showError } = useNotifications()
  const { mutateAsync: createTarget } = useCreateTarget(organization.id, project.id, {
    onSuccess: () => showSuccess('Target successfully created'),
    onError: ({ message }) => showError(message),
  })

  const {
    register,
    control,
    handleSubmit,
    formState: { isSubmitting, isValid, errors },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    shouldFocusError: false,
    defaultValues: {
      name: '',
      platform: '',
      branch: '',
      ueVersion: '',
    },
  })

  const sortedBranches = useMemo(() => sortBy(branches.items, 'name'), [branches])

  const submit = async ({ name, branch, platform, ueVersion }) => {
    await createTarget({ name, branch, platform, ueVersion })
    onClose()
  }

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth>
      <DialogTitle>{'Create New Target'}</DialogTitle>

      <DialogContent>
        <TextField
          InputProps={{ ...register('name') }}
          name="name"
          label="Name"
          variant="outlined"
          fullWidth
          autoFocus
        />
        <ErrorText>{errors.name?.message}</ErrorText>

        <br />
        <br />

        <Controller
          name="platform"
          control={control}
          defaultValue=""
          rules={{ required: 'Required' }}
          render={({ field }) => (
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="platform-id-label">Platform</InputLabel>
              <Select {...field} id="platformId" labelId="platform-id-label" label="Platform">
                {Platforms.map(({ name, value, enabled }) => (
                  <MenuItem key={value} value={value} disabled={!enabled}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>The platform for which to build the project</FormHelperText>
              <ErrorText>{errors.platform?.message}</ErrorText>
            </FormControl>
          )}
        />

        <br />
        <br />

        <Controller
          name="branch"
          control={control}
          defaultValue=""
          rules={{ required: 'Required' }}
          render={({ field }) => (
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="branch-id-label">Branch</InputLabel>
              <Select
                {...field}
                id="branchId"
                labelId="branch-id-label"
                label="Branch"
                disabled={branchesLoading || !!branchesError}
              >
                {sortedBranches.map(({ name }) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>Your repository branch to checkout during build</FormHelperText>
              <ErrorText>{errors.branch?.message}</ErrorText>
            </FormControl>
          )}
        />

        <br />
        <br />

        <Controller
          name="ueVersion"
          control={control}
          defaultValue=""
          rules={{ required: 'Required' }}
          render={({ field }) => (
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="ueVersion-id-label">Unreal Engine Version</InputLabel>
              <Select
                {...field}
                id="ueVersionId"
                labelId="ueVersion-id-label"
                label="UE Version"
                disabled={ueVersionsLoading || !!ueVersionsError}
              >
                {ueVersions.map(({ id, version }) => (
                  <MenuItem key={id} value={version}>
                    {version}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>The Unreal Engine version to build with</FormHelperText>
              <ErrorText>{errors.ueVersion?.message}</ErrorText>
            </FormControl>
          )}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={isSubmitting || !isValid}
          onClick={handleSubmit(submit)}
        >
          {isSubmitting ? 'Creating...' : 'Create'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

CreateTargetModal.propTypes = {
  organization: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

import { object, string, ref } from 'yup'

export const name = string().required().label('Name')

export const firstName = string().required().label('First Name')

export const lastName = string().required().label('Last Name')

export const email = string().email().required().label('Email')

export const password = string().min(6).required().label('Password')

export const passwordConfirm = string()
  .oneOf([ref('password'), null], 'Passwords must match')
  .label('Password Confirmation')

export const role = string().oneOf(['user', 'admin']).required().label('Role')

export const loginSchema = object().shape({
  email,
  password: string().required().label('Password'),
})

export const signupSchema = object().shape({
  firstName,
  lastName,
  email,
  password,
  passwordConfirm,
})

export const editProfileSchema = object().shape({
  firstName,
  lastName,
  email,
})

export const editPasswordSchema = object().shape({
  password,
  passwordConfirm,
})

export const resetPasswordSchema = object().shape({
  email,
})

export const inviteMemberSchema = object().shape({
  email,
  role,
})

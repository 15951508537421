import { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import { Page } from '../../components/Reusable'
import { useAuth } from '../../hooks/useAuth'
import { appName } from '../../config'
import { LoginForm } from './Form'

const useStyles = makeStyles(({ palette }) => ({
  h1: {
    textAlign: 'center',
    marginBottom: 30,
  },
  col: {
    background: palette.common.white,
    border: `1px solid ${palette.grey[400]}`,
    padding: '50px 30px',
    borderRadius: 10,
  },
  linksContainer: {
    color: palette.common.black,
    textAlign: 'center',
    marginTop: 15,
  },
  link: {
    color: palette.primary.main,
  },
}))

export const LoginPage = () => {
  const [user] = useAuth()
  const classes = useStyles()

  const handleOnLogin = () => {
    window.location.assign('/')
  }

  useEffect(() => {
    if (user) {
      window.location.assign('/')
    }
  }, [user])

  return (
    <Page>
      <Container maxWidth="sm" className={classes.col}>
        <h1 className={classes.h1}>Login to {appName}</h1>

        <LoginForm onLogin={handleOnLogin} />

        <div className={classes.linksContainer}>
          <a className={classes.link} href={'/signup'}>
            Create an account
          </a>
          <span> • </span>
          <a className={classes.link} href={'/forgot-password'}>
            Forgot Password
          </a>
        </div>
      </Container>
    </Page>
  )
}

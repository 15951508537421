import { QueryClient } from '@tanstack/react-query'
import uniqBy from 'lodash/uniqBy'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5,
      retry: 2,
      retryOnMount: true,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      initialDataUpdatedAt: 0,
    },
    mutations: {
      retry: false,
    },
  },
})

export const addQueryData = (queryKey, element, key = 'id') =>
  queryClient.setQueryData(queryKey, (elements) => uniqBy([element, ...elements], key))

export const updateQueryData = (queryKey, element, key = 'id') =>
  queryClient.setQueryData(queryKey, (elements) =>
    Array.isArray(elements)
      ? elements.map((e) => (e[key] === element[key] ? element : e))
      : element,
  )

export const removeQueryData = (queryKey, element, key = 'id') =>
  queryClient.setQueryData(queryKey, (elements) =>
    Array.isArray(elements) ? elements.filter((e) => e[key] !== element[key]) : null,
  )

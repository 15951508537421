import { useMutation, useQuery } from '@tanstack/react-query'
import { addQueryData, removeQueryData, updateQueryData } from '../../libs/react-query'
import {
  cancelBuild,
  createBuild,
  deleteBuild,
  getArtifactSignedUrl,
  getBuild,
  getBuildLog,
  getBuildMetadata,
  getBuilds,
  getBuildUsagesByProjectId,
} from './builds.io'

export const queryIds = {
  useBuilds: (organizationId, projectId) => [
    'organizations',
    organizationId,
    'projects',
    projectId,
    'builds',
  ],
  useBuildUsages: (organizationId, projectId) => [
    'organizations',
    organizationId,
    'projects',
    projectId,
    'builds',
    'usages',
  ],
  useBuild: (organizationId, projectId, buildId) => [
    'organizations',
    organizationId,
    'projects',
    projectId,
    'builds',
    buildId,
  ],
  useBuildMetadata: (organizationId, projectId, buildId) => [
    'organizations',
    organizationId,
    'projects',
    projectId,
    'builds',
    buildId,
    'metadata',
  ],
  useBuildLog: (organizationId, projectId, buildId) => [
    'organizations',
    organizationId,
    'projects',
    projectId,
    'builds',
    buildId,
    'log',
  ],
  useArtifactSignedUrl: (organizationId, projectId, buildId, artifact) => [
    'organizations',
    organizationId,
    'projects',
    projectId,
    'builds',
    buildId,
    'artifacts',
    artifact,
    'signed-url',
  ],
}

export const useBuilds = (organizationId, projectId, options = {}) =>
  useQuery(
    queryIds.useBuilds(organizationId, projectId),
    () => getBuilds(organizationId, projectId),
    options,
  )

export const useBuild = (organizationId, projectId, buildId, options = {}) =>
  useQuery(
    queryIds.useBuild(organizationId, projectId, buildId),
    () => getBuild(organizationId, projectId, buildId),
    options,
  )

export const useBuildUsages = (organizationId, projectId, options = {}) =>
  useQuery(
    queryIds.useBuildUsages(organizationId, projectId),
    () => getBuildUsagesByProjectId(organizationId, projectId),
    options,
  )

export const useBuildMetadata = (organizationId, projectId, buildId, options = {}) =>
  useQuery(
    queryIds.useBuildMetadata(organizationId, projectId, buildId),
    () => getBuildMetadata(organizationId, projectId, buildId),
    options,
  )

export const useBuildLog = (organizationId, projectId, buildId, options = {}) =>
  useQuery(
    queryIds.useBuildLog(organizationId, projectId, buildId),
    () => getBuildLog(organizationId, projectId, buildId),
    {
      cacheTime: 0, // logs are heavy, don't cache them
      ...options,
    },
  )

export const useArtifactSignedUrl = (organizationId, projectId, buildId, artifact, options = {}) =>
  useQuery(
    queryIds.useArtifactSignedUrl(organizationId, projectId, buildId, artifact),
    () => getArtifactSignedUrl(organizationId, projectId, buildId, artifact),
    options,
  )

export const useCreateBuild = (organizationId, projectId, options = {}) =>
  useMutation(({ targetId }) => createBuild(organizationId, projectId, { targetId }), {
    ...options,
    onSuccess: (build) => {
      addQueryData(queryIds.useBuilds(organizationId, projectId), build)
      options?.onSuccess?.(build)
    },
  })

export const useCancelBuild = (organizationId, projectId, buildId, options = {}) =>
  useMutation(() => cancelBuild(organizationId, projectId, buildId), {
    ...options,
    onSuccess: (build) => {
      updateQueryData(queryIds.useBuilds(organizationId, projectId), build)
      options?.onSuccess?.(build)
    },
  })

export const useDeleteBuild = (organizationId, projectId, buildId, options = {}) =>
  useMutation(() => deleteBuild(organizationId, projectId, buildId), {
    ...options,
    onSuccess: (build) => {
      removeQueryData(queryIds.useBuilds(organizationId, projectId), build)
      options?.onSuccess?.(build)
    },
  })

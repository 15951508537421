import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import { useConfirm } from 'material-ui-confirm'
import { useOpenable } from '../../hooks/useOpenable'
import {
  useOrganizationPaymentMethods,
  useDeleteOrganizationPaymentMethod,
  useSetOrganizationDefaultPaymentMethod,
} from '../../services/billing/billing.queries'
import { PaymentMethodPropType } from '../proptypes'

const useStyles = makeStyles(({ palette }) => ({
  deleteButton: {
    color: palette.error.main,
  },
  cardConfirmDeleteButton: {
    color: palette.error.main,
  },
}))

export const PaymentMethodMenu = (props) => {
  const { organizationId, paymentMethod } = props

  const classes = useStyles()
  const confirm = useConfirm()
  const { open, close, isOpen, anchor } = useOpenable()
  const { refetch: refetchCards } = useOrganizationPaymentMethods(organizationId, {
    enabled: false,
  })
  const { mutateAsync: setDefaultAsync, isLoading: isLoadingDefault } =
    useSetOrganizationDefaultPaymentMethod(organizationId)
  const { mutateAsync: deleteAsync, isLoading: isLoadingDelete } =
    useDeleteOrganizationPaymentMethod(organizationId)

  const handleSetDefault = async (event) => {
    event.preventDefault()

    await setDefaultAsync(paymentMethod.id)
    close()
  }

  const handleDelete = (event) => {
    event.preventDefault()

    confirm({
      description: 'This action is permanent!',
      dialogProps: { maxWidth: 'xs' },
      confirmationText: 'Delete',
      confirmationButtonProps: { className: classes.cardConfirmDeleteButton },
    })
      .then(async () => {
        await deleteAsync(paymentMethod.id)
        await refetchCards()
        close()
      })
      .catch(() => {
        // do nothing
      })
  }

  return (
    <>
      <IconButton onClick={open} size="small">
        <MoreHorizIcon fontSize="small" />
      </IconButton>

      <Menu anchorEl={anchor} open={isOpen} onClose={close}>
        {!paymentMethod.isDefault && (
          <MenuItem onClick={handleSetDefault} disabled={isLoadingDefault}>
            {isLoadingDefault ? 'Setting default' : 'Set default'}
          </MenuItem>
        )}

        <MenuItem
          className={classes.deleteButton}
          onClick={handleDelete}
          disabled={isLoadingDelete}
        >
          {isLoadingDelete ? 'Deleting' : 'Delete'}
        </MenuItem>
      </Menu>
    </>
  )
}

PaymentMethodMenu.propTypes = {
  organizationId: PropTypes.string.isRequired,
  paymentMethod: PaymentMethodPropType,
}

import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { useNotifications } from '../../hooks/useNotifications'
import { useUpdateMemberRole } from '../../services/organizations/organizations.queries'

const useStyles = makeStyles(({ spacing }) => ({
  select: {
    marginRight: spacing(1),
  },
  root: {
    padding: `${spacing(1)}px ${spacing(2)}px`,
  },
}))

export const RoleSelect = (props) => {
  const { member, organizationId, isOrgAdmin, ...rest } = props

  const classes = useStyles()
  const { showSuccess, showError } = useNotifications()
  const { mutateAsync } = useUpdateMemberRole(organizationId, member.id, {
    onSuccess: () => showSuccess('Role successfully updated'),
    onError: ({ message }) => showError(message),
  })

  const handleOnChange = async (e) => {
    const role = e.target.value
    await mutateAsync({ role })
  }

  return (
    <Select
      className={classes.select}
      classes={{ root: classes.root }}
      value={member.role}
      onChange={handleOnChange}
      disabled={!isOrgAdmin}
      variant="outlined"
      {...rest}
    >
      <MenuItem value="user">User</MenuItem>
      <MenuItem value="admin">Admin</MenuItem>
    </Select>
  )
}

RoleSelect.propTypes = {
  isOrgAdmin: PropTypes.bool.isRequired,
  member: PropTypes.object.isRequired,
  organizationId: PropTypes.string.isRequired,
}

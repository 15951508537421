import { useSearchParams } from 'react-router-dom'
import { parse } from 'querystring'

export const useQueryParams = () => {
  const [searchParams, setSearch] = useSearchParams()

  const queryParams = parse(searchParams.toString())
  const setQueryParams = (queryParams) => setSearch(queryParams)

  return [queryParams, setQueryParams]
}

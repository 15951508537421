import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { QueryClientProvider } from '@tanstack/react-query'
import { SnackbarProvider } from 'notistack'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/core/styles'
import { ConfirmProvider } from 'material-ui-confirm'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { environment, sentryDsn, version } from './config'
import { App } from './App'
import { lightTheme } from './theme'
import { queryClient } from './libs/react-query'
import { snackbarProps } from './libs/snackbarProps'
import * as serviceWorker from './serviceWorker'
import { AuthProvider } from './hooks/useAuth'
import './index.css'

// must be imported at the root to maximize fraud detection
import '@stripe/stripe-js'

Sentry.init({
  environment,
  enabled: environment !== 'local',
  dsn: sentryDsn,
  release: version,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
})

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <QueryClientProvider client={queryClient}>
    <ThemeProvider theme={lightTheme}>
      <SnackbarProvider {...snackbarProps}>
        <BrowserRouter>
          <AuthProvider>
            <ConfirmProvider>
              <CssBaseline />
              <App />
            </ConfirmProvider>
          </AuthProvider>
        </BrowserRouter>
      </SnackbarProvider>
    </ThemeProvider>
  </QueryClientProvider>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()

import { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import { Alert } from '../../components/Alerts'
import { Link } from '../../components/link/Link'
import { Spinner } from '../../components/Reusable'
import { Router } from '../../components/routers/Router'
import { useAuth } from '../../hooks/useAuth'
import { isEntityAdmin } from '../../utils'
import { OrgDetails } from './OrgDetails'
import { OrgMembers } from './OrgMembers'
import { OrgProjectList } from './org-project-list/OrgProjectList'
import { DashboardPage } from '../../components/dashboard-page/DashboardPage'
import { useOrganization } from '../../services/organizations/organizations.queries'

export const WithOrganization = ({ routes }) => {
  const [user] = useAuth()
  const { organizationId } = useParams()
  const { data: organization, isLoading, error, remove } = useOrganization(organizationId)

  const isOrgAdmin = useMemo(() => isEntityAdmin(user?.id, organization), [user, organization])

  useEffect(() => remove, [])

  if (isLoading) {
    return <Spinner isLoading={isLoading} />
  }

  if (error) {
    return <Alert show={!!error} message={error?.message} severity="error" />
  }

  if (!isOrgAdmin) {
    return <Alert show={!!error} message={'Seems like you are not an org admin'} severity="error" />
  }

  return <Router routes={routes} organization={organization} />
}

export const OrganizationPage = (props) => {
  const { organization } = props

  const [user] = useAuth()

  const isOrgAdmin = useMemo(() => isEntityAdmin(user.id, organization), [user, organization])

  return (
    <DashboardPage
      title={<Link to="/organizations" icon={<ChevronLeft />} label="Organizations" />}
    >
      <>
        <OrgDetails organization={organization} isOrgAdmin={isOrgAdmin} />
        <Box marginBottom={4} />
        <OrgProjectList organization={organization} />
        <Box marginBottom={4} />
        <OrgMembers organizationId={organization.id} isOrgAdmin={isOrgAdmin} />
      </>
    </DashboardPage>
  )
}

OrganizationPage.propTypes = {
  organization: PropTypes.object.isRequired,
}

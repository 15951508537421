import { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import parseISO from 'date-fns/parseISO'
import sortBy from 'lodash/sortBy'
import List from '@material-ui/core/List'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import { Link } from '../../components/link/Link'
import { Alert } from '../../components/Alerts'
import { Placeholder } from '../../components/placeholder/Placeholder'
import { Spinner } from '../../components/Reusable'
import { DashboardPage } from '../../components/dashboard-page/DashboardPage'
import { addQueryData, removeQueryData, updateQueryData } from '../../libs/react-query'
import { queryIds, useBuilds } from '../../services/builds/builds.queries'
import { CreateBuildModal } from './CreateBuildModal'
import { BuildListItem } from './BuildListItem'
import { pusher } from '../../libs/pusher'

import BuildsImage from '../../assets/illustrations/abstract_build.svg'

export const BuildList = (props) => {
  const { organization, project } = props

  const queryId = queryIds.useBuilds(organization.id, project.id)

  const [isCreateModalOpen, setCreateModalOpen] = useState(false)
  const { data: builds, isLoading, error } = useBuilds(organization.id, project.id)

  // subscribe to Pusher project builds events
  // uniq keeps the first unique element, 'build' must be first
  useEffect(() => {
    const channelName = `private-project-${project.id}`
    const channel = pusher.subscribe(channelName)

    channel.bind('build-create', (build) => addQueryData(queryId, build))
    channel.bind('build-update', (build) => updateQueryData(queryId, build))
    channel.bind('build-delete', (build) => removeQueryData(queryId, build))

    return () => pusher.unsubscribe(channelName)
  }, [project.id])

  const sortedBuilds = useMemo(
    () => sortBy(builds, ({ createdAt }) => -parseISO(createdAt).valueOf()),
    [builds],
  )

  if (isLoading) {
    return <Spinner isLoading={isLoading} />
  }

  return (
    <DashboardPage title={<Link to="/" label="Overview" />}>
      {isCreateModalOpen && (
        <CreateBuildModal
          organizationId={organization.id}
          projectId={project.id}
          isOpen={isCreateModalOpen}
          onClose={() => setCreateModalOpen(false)}
        />
      )}

      <Card>
        <CardHeader
          title="Builds"
          action={
            <CardActions disableSpacing>
              <Button
                color="primary"
                variant="contained"
                size="small"
                onClick={() => setCreateModalOpen(true)}
              >
                + Trigger Build
              </Button>
            </CardActions>
          }
        />

        <CardContent>
          <Alert show={!!error} message={error?.message || error} severity="error" />

          {!sortedBuilds.length && (
            <Placeholder
              imageSrc={BuildsImage}
              imageAlt="No Builds"
              title="Trigger Your First Build"
              description="Trigger your first build from a build target."
            />
          )}

          <List>
            {sortedBuilds.map((build) => (
              <BuildListItem
                key={build.id}
                organization={organization}
                project={project}
                build={build}
              />
            ))}
          </List>
        </CardContent>
      </Card>
    </DashboardPage>
  )
}

BuildList.propTypes = {
  organization: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
}

import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import parseISO from 'date-fns/parseISO'
import { makeStyles } from '@material-ui/core/styles'
import DeleteIcon from '@material-ui/icons/DeleteOutlined'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import { useConfirm } from 'material-ui-confirm'
import { ListItemSecondaryAction } from '@material-ui/core'
import { Avatar } from '../../../components/avatar/Avatar'
import { useDeleteProject } from '../../../services/projects/projects.queries'
import { useNotifications } from '../../../hooks/useNotifications'

const useStyles = makeStyles(({ palette }) => ({
  deleteButton: {
    color: palette.error.main,
  },
  deleteButtonIcon: {
    '&:hover': {
      color: palette.error.main,
    },
  },
}))

export const ProjectListItem = (props) => {
  const { project, isOrgAdmin } = props

  const classes = useStyles()
  const confirm = useConfirm()
  const { showSuccess, showError } = useNotifications()

  const { mutateAsync: deleteProject, isLoading } = useDeleteProject(
    project.organizationId,
    project.id,
    {
      onSuccess: () => showSuccess('Project successfully deleted'),
      onError: ({ message }) => showError(message),
    },
  )

  const handleDelete = () => {
    confirm({
      description: 'This action is permanent!',
      dialogProps: { maxWidth: 'xs' },
      confirmationText: 'Delete',
      confirmationButtonProps: { className: classes.deleteButton },
    })
      .then(deleteProject)
      .catch(() => {
        // do nothing
      })
  }

  return (
    <ListItem
      key={project.id}
      button
      component={Link}
      to={`/organizations/${project.organizationId}/projects/${project.id}`}
    >
      <ListItemAvatar>
        <Avatar user={project} />
      </ListItemAvatar>

      <ListItemText
        primary={project.name}
        secondary={`Created ${formatDistanceToNow(parseISO(project.createdAt), {
          addSuffix: true,
        })}`}
      />

      <ListItemSecondaryAction>
        <Tooltip
          title="You need to be an org admin to delete a project from it"
          disableHoverListener={isOrgAdmin}
          disableFocusListener={isOrgAdmin}
          disableTouchListener={isOrgAdmin}
        >
          <span>
            <IconButton
              className={classes.deleteButtonIcon}
              onClick={handleDelete}
              disabled={!isOrgAdmin || isLoading}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </span>
        </Tooltip>
      </ListItemSecondaryAction>
    </ListItem>
  )
}

ProjectListItem.propTypes = {
  project: PropTypes.object.isRequired,
  isOrgAdmin: PropTypes.bool.isRequired,
}

import { useMutation, useQuery } from '@tanstack/react-query'
import { addQueryData, removeQueryData, updateQueryData } from '../../libs/react-query'
import { createProject, deleteProject, getProject, getProjects, updateProject } from './projects.io'

export const queryIds = {
  useProjects: (organizationId) => ['organizations', organizationId, 'projects'],
  useProject: (organizationId, projectId) => [
    'organizations',
    organizationId,
    'projects',
    projectId,
  ],
}

export const useProjects = (organizationId, options = {}) =>
  useQuery(queryIds.useProjects(organizationId), () => getProjects(organizationId), options)

export const useProject = (organizationId, projectId, options = {}) =>
  useQuery(
    queryIds.useProject(organizationId, projectId),
    () => getProject(organizationId, projectId),
    options,
  )

export const useCreateProject = (organizationId, options = {}) =>
  useMutation(({ name }) => createProject(organizationId, { name }), {
    ...options,
    onSuccess: (project) => {
      addQueryData(queryIds.useProjects(organizationId), project)
      options?.onSuccess?.(project)
    },
  })

export const useUpdateProject = (organizationId, projectId, options = {}) =>
  useMutation(({ payload }) => updateProject(organizationId, projectId, payload), {
    ...options,
    onSuccess: (project) => {
      updateQueryData(queryIds.useProjects(organizationId), project)
      options?.onSuccess?.(project)
    },
  })

export const useDeleteProject = (organizationId, projectId, options = {}) =>
  useMutation(() => deleteProject(organizationId, projectId), {
    ...options,
    onSuccess: (project) => {
      removeQueryData(queryIds.useProjects(organizationId), project)
      options?.onSuccess?.(project)
    },
  })

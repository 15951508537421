import PropTypes from 'prop-types'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import MuiCard from '@material-ui/core/Card'
import MuiCardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { BuildCreation } from '../../components/build/BuildCreation'
import { BuildDuration } from '../../components/build/BuildDuration'
import { BuildBillableTime } from '../../components/build/BuildBillableTime'
import { VerticalAlign } from '../../components/vertical-align/VerticalAlign'
import { BuildFavicon } from '../../components/build/build-favicon/BuildFavicon'
import { BuildIcon } from '../../components/build/BuildIcon'
import { BuildListItemMenu } from '../build-list/BuildListItemMenu'
import { BuildLog } from './BuildLog'

const Card = withStyles(({ palette }) => ({
  root: {
    borderLeft: ({ status }) => `4px ${palette.status[status]} solid`,
  },
}))(MuiCard)

const CardHeader = withStyles(() => ({
  action: {
    alignSelf: 'center',
    margin: 0,
  },
}))(MuiCardHeader)

const useStyles = makeStyles(({ spacing }) => ({
  cardContent: {
    paddingBottom: spacing(2),
  },
  cardContentLog: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
  },
}))

export const BuildDetails = (props) => {
  const { organization, project, build } = props

  const classes = useStyles()

  return (
    <Card status={build.status}>
      <BuildFavicon build={build} />

      <CardHeader
        avatar={<BuildIcon status={build.status} />}
        action={<BuildListItemMenu organization={organization} project={project} build={build} />}
        title={
          <Typography variant="h6">
            #{build.number} - {build.target.name}
          </Typography>
        }
        subheader={
          <VerticalAlign>
            <Typography variant="body1">
              <BuildCreation build={build} />
            </Typography>
            &nbsp; &nbsp;
            <Typography variant="body1">
              <BuildDuration build={build} />
            </Typography>
            &nbsp; &nbsp;
            <Typography variant="body1">
              <BuildBillableTime build={build} />
            </Typography>
          </VerticalAlign>
        }
      ></CardHeader>
      <CardContent className={classes.cardContentLog}>
        <BuildLog organization={organization} project={project} build={build} />
      </CardContent>
    </Card>
  )
}

BuildDetails.propTypes = {
  organization: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
  build: PropTypes.object.isRequired,
}

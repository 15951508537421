import { useMemo } from 'react'
import PropTypes from 'prop-types'
import isEqual from 'lodash/isEqual'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardActions from '@material-ui/core/CardActions'
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { useConfirm } from 'material-ui-confirm'
import { SubscriptionPropType } from '../proptypes'
import {
  useCancelOrganizationSubscription,
  useCreateOrganizationSubscription,
  useUpdateOrganizationSubscription,
} from '../../services/billing/billing.queries'
import { formatSubscriptionAsRequest, normalizeSubscriptionRequest } from '../../utils'
import { useNotifications } from '../../hooks/useNotifications'

const useStyles = makeStyles(({ palette, spacing }) => ({
  card: {
    padding: spacing(0.5),
  },
  button: {
    marginTop: spacing(0.5),
    marginRight: spacing(0.5),
  },
  confirmDeleteButton: {
    color: palette.error.main,
  },
}))

export const UpdateSubscription = (props) => {
  const { organizationId, subscription, subscriptionRequest, paymentMethods } = props

  const onError = ({ message }) => showError(message)

  const { mutateAsync: createSubscription, isLoading: isLoadingCreateSubscription } =
    useCreateOrganizationSubscription(organizationId, { onError })
  const { mutateAsync: updateSubscription, isLoading: isLoadingUpdateSubscription } =
    useUpdateOrganizationSubscription(organizationId, subscription?.id, { onError })
  const { mutateAsync: cancelSubscription, isLoading: isLoadingCancelSubscription } =
    useCancelOrganizationSubscription(organizationId, subscription?.id, { onError })

  const classes = useStyles()
  const confirm = useConfirm()
  const { showSuccess, showError } = useNotifications()

  const subscriptionAsRequest = useMemo(
    () => formatSubscriptionAsRequest(subscription),
    [subscription],
  )

  const isPristine = useMemo(() => {
    const normalizedRequest = normalizeSubscriptionRequest(subscriptionRequest)
    const normalizedSubscription = normalizeSubscriptionRequest(subscriptionAsRequest)
    return isEqual(normalizedRequest, normalizedSubscription)
  }, [subscriptionRequest, subscriptionAsRequest])

  const tooltipTitle = useMemo(() => {
    if (!paymentMethods.length) {
      return 'You need a payment method before subscribing'
    } else if (!subscriptionRequest.plan) {
      return 'You need to select a plan to subscribe'
    } else {
      return ''
    }
  }, [])

  const handleSubscribe = async () => {
    const request = {
      items: [
        // plan
        {
          priceId: subscriptionRequest.plan,
          quantity: 1,
        },
        // addons
        ...Object.entries(subscriptionRequest.addons).map(([priceId, quantity]) => ({
          priceId,
          quantity,
        })),
      ],
    }

    if (subscription?.id) {
      await updateSubscription(request)
      await showSuccess('Subscription successfully updated')
    } else {
      await createSubscription(request)
      await showSuccess('Congratulations, you are now subscribed!')
    }
  }

  const handleCancel = () => {
    confirm({
      description:
        'Are you sure you want to cancel your subscription? You will not be able to start any more builds.',
      dialogProps: { maxWidth: 'xs' },
      cancellationText: 'Close',
      confirmationText: 'Cancel Subscription',
      confirmationButtonProps: { className: classes.confirmDeleteButton },
    })
      .then(async () => {
        await cancelSubscription()
        showSuccess('Subscription successfully canceled')
      })
      .catch(() => {
        // do nothing
      })
  }

  return (
    <Card className={classes.card}>
      <CardHeader
        title={
          !subscription?.items?.length && (
            <Typography variant="subtitle2" color="error">
              Your organization is not subscribed yet
            </Typography>
          )
        }
        action={
          <CardActions>
            <Tooltip title={tooltipTitle}>
              <span>
                <Button
                  size="large"
                  color="primary"
                  variant="contained"
                  disabled={
                    !subscriptionRequest.plan ||
                    isPristine ||
                    isLoadingCreateSubscription ||
                    isLoadingUpdateSubscription ||
                    !paymentMethods.length
                  }
                  onClick={handleSubscribe}
                  className={classes.button}
                >
                  {isLoadingCreateSubscription || isLoadingUpdateSubscription
                    ? 'Updating Subscription'
                    : 'Update Subscription'}
                </Button>
              </span>
            </Tooltip>

            {subscription && (
              <Button
                size="large"
                color="secondary"
                variant="contained"
                disabled={isLoadingCancelSubscription}
                onClick={handleCancel}
                className={classes.button}
              >
                {isLoadingCancelSubscription ? 'Canceling Subscription' : 'Cancel Subscription'}
              </Button>
            )}
          </CardActions>
        }
      />
    </Card>
  )
}

UpdateSubscription.propTypes = {
  organizationId: PropTypes.string.isRequired,
  paymentMethods: PropTypes.array.isRequired,
  subscription: SubscriptionPropType,
  subscriptionRequest: PropTypes.shape({
    plan: PropTypes.string,
    addons: PropTypes.object,
  }),
}

UpdateSubscription.defaultProps = {
  paymentMethods: [],
  subscription: null,
  subscriptionRequest: {},
}

import { UCloudLogo, UnrealCloudLogo } from '../../components/logo/Logo'

const styles = {
  header: {
    display: 'inline-flex',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    padding: 15,
    zIndex: 9999,
  },
  logos: {
    height: 30,
  },
  ulogo: {
    height: 45,
    marginRight: 10,
  },
}

export const Header = () => (
  <div style={styles.header}>
    <UCloudLogo style={{ ...styles.logos, ...styles.ulogo }} />
    <UnrealCloudLogo style={styles.logos} />
  </div>
)

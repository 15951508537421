import { useState, useEffect } from 'react'
import Container from '@material-ui/core/Container'
import ReactMarkdown from 'react-markdown'
import { get } from 'axios'
import { Section } from '../../components/section/Section'
import PrivacyPolicyMD from './privacy-policy.md'

export const PrivacyPolicy = () => {
  const [text, setText] = useState('')

  useEffect(() => {
    get(PrivacyPolicyMD).then(({ data }) => setText(data))
  }, [])

  return (
    <Section>
      <Container>
        <ReactMarkdown source={text} />
      </Container>
    </Section>
  )
}

import PropTypes from 'prop-types'
import { darken, makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import GithubIcon from '@material-ui/icons/GitHub'

const GITHUB_COLOR = '#26292E'

const useStyles = makeStyles(({ palette, spacing }) => ({
  button: {
    color: palette.common.white,
    backgroundColor: GITHUB_COLOR,
    '&:hover': {
      color: palette.common.white,
      backgroundColor: darken(GITHUB_COLOR, 1),
    },
  },
  icon: {
    marginRight: spacing(1),
  },
}))

export const GithubButton = (props) => {
  const { title, onClick, ...rest } = props

  const classes = useStyles()

  return (
    <Button className={classes.button} onClick={onClick} {...rest}>
      <GithubIcon className={classes.icon} />
      {title}
    </Button>
  )
}

GithubButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string,
}

GithubButton.defaultProps = {
  title: 'Connect with Github',
}

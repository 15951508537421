import { useMemo, useState } from 'react'
import parseISO from 'date-fns/parseISO'
import sortBy from 'lodash/sortBy'
import List from '@material-ui/core/List'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import { Link } from '../../components/link/Link'
import { Alert } from '../../components/Alerts'
import { Placeholder } from '../../components/placeholder/Placeholder'
import { Spinner } from '../../components/Reusable'
import { useOrganizations } from '../../services/organizations/organizations.queries'
import { DashboardPage } from '../../components/dashboard-page/DashboardPage'
import { CreateOrgModal } from './CreateOrgModal'
import { OrganizationListItem } from './OrganizationListItem'

import NoOrganizationsImage from '../../assets/illustrations/empty.svg'

export const OrganizationList = () => {
  const [isCreateModalOpen, setCreateModalOpen] = useState(false)

  const { isLoading, error, data } = useOrganizations()

  const sortedOrgs = useMemo(
    () => sortBy(data, ({ createdAt }) => -parseISO(createdAt).valueOf()),
    [data],
  )

  return (
    <DashboardPage title={<Link to="/" label="Overview" />}>
      <CreateOrgModal isOpen={isCreateModalOpen} onClose={() => setCreateModalOpen(false)} />

      <Card>
        <CardHeader
          title="Organizations"
          action={
            <CardActions disableSpacing>
              <Button
                color="primary"
                variant="contained"
                size="small"
                onClick={() => setCreateModalOpen(true)}
              >
                + Add New Org
              </Button>
            </CardActions>
          }
        />

        <CardContent>
          <Spinner isLoading={isLoading} />
          <Alert show={!!error} message={error} severity="error" />

          {!sortedOrgs.length && !isLoading && (
            <Placeholder
              imageSrc={NoOrganizationsImage}
              imageAlt="No Organizations"
              title="Add Your First Org"
              description="Adding organizations allows you to manage your projects and team members."
            />
          )}
          <List>
            {sortedOrgs.map((org) => (
              <OrganizationListItem key={org.id} org={org} />
            ))}
          </List>
        </CardContent>
      </Card>
    </DashboardPage>
  )
}

import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import { BasePlan } from './BasePlan'
import { PlanPropType, SubscriptionItemPropType } from '../proptypes'

export const Addon = (props) => {
  const { plan, subscriptionItem, onChange } = props
  const [count, setCount] = useState(subscriptionItem?.quantity || 0)

  useEffect(() => {
    onChange(count)
  }, [count])

  const decrement = () => setCount((prev) => (prev <= 0 ? 0 : prev - 1))
  const increment = () => setCount((prev) => prev + 1)

  return (
    <BasePlan
      plan={plan}
      currentLabel={subscriptionItem?.quantity}
      actions={
        <>
          <IconButton color="primary" size="small" onClick={decrement}>
            <ChevronLeft />
          </IconButton>

          <Box display="inline-flex" justifyContent="center" alignItems="center" mx={1}>
            <Typography variant="button">{count}</Typography>
          </Box>

          <IconButton color="primary" size="small" onClick={increment}>
            <ChevronRight />
          </IconButton>
        </>
      }
    />
  )
}

Addon.propTypes = {
  onChange: PropTypes.func.isRequired,
  plan: PlanPropType.isRequired,
  subscriptionItem: SubscriptionItemPropType,
}

Addon.defaultProps = {
  subscriptionItem: null,
}

import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import { DashboardPage } from '../../components/dashboard-page/DashboardPage'
import { Alert } from '../../components/Alerts'
import { Link } from '../../components/link/Link'
import { Spinner } from '../../components/Reusable'
import { Router } from '../../components/routers/Router'
import { BuildDetails } from './BuildDetails'
import { updateQueryData } from '../../libs/react-query'
import { queryIds, useBuild } from '../../services/builds/builds.queries'
import { pusher } from '../../libs/pusher'

export const WithBuild = ({ routes, ...rest }) => {
  const { organizationId, projectId, buildId } = useParams()

  const queryId = queryIds.useBuild(organizationId, projectId, buildId)

  const {
    data: build,
    isLoading,
    error,
  } = useBuild(organizationId, projectId, buildId, {
    cacheTime: 0,
  })

  // subscribe to Pusher build events
  useEffect(() => {
    const channelName = `private-build-${buildId}`
    const channel = pusher.subscribe(channelName)

    channel.bind('build-update', (build) => updateQueryData(queryId, build))

    return () => pusher.unsubscribe(channelName)
  }, [buildId])

  if (isLoading) {
    return <Spinner isLoading={isLoading} />
  }

  if (error) {
    return <Alert show={!!error} message={error?.message} severity="error" />
  }

  return <Router routes={routes} build={build} {...rest} />
}

export const BuildPage = (props) => {
  const { organization, project, build } = props

  return (
    <DashboardPage
      title={
        <Link
          to={`/organizations/${organization.id}/projects/${project.id}/builds`}
          icon={<ChevronLeft />}
          label="Build List"
        />
      }
    >
      <BuildDetails organization={organization} project={project} build={build} />
    </DashboardPage>
  )
}

BuildPage.propTypes = {
  organization: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
  build: PropTypes.object.isRequired,
}

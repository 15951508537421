import { forwardRef } from 'react'
import { NavLink as RouterNavLink } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(({ palette }) => ({
  activeClassName: {
    color: palette.primary.main,
  },
}))

export const NavLink = forwardRef((props, ref) => {
  const classes = useStyles()

  return (
    <RouterNavLink
      ref={ref}
      {...props}
      className={({ isActive }) =>
        [props.className, isActive ? classes.activeClassName : null].filter(Boolean).join(' ')
      }
      end
    />
  )
})

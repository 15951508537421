// https://paletton.com/#uid=7050u0krkt+h0Drm3vKuloBynjv
export const Colors = {
  red: {
    main: '#F32000',
    light: '#FF391B',
    dark: '#CC1B00',
  },
  orange: {
    main: '#FF9A01',
    light: '#FFA725',
    dark: '#D07D00',
  },
  blue: {
    main: '#0079F3',
    light: '#3DC2F9',
    dark: '#004287',
  },
  yellow: {
    main: '#EF8823',
    light: '#FFBB77',
    dark: '#9B4D00',
  },
  green: {
    main: '#1AB342',
    light: '#61D07E',
    dark: '#00741F',
  },
  dark: {
    main: '#121212',
    light: '#F3F3F3',
    dark: '#000000',
  },
}

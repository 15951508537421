import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import StarIcon from '@material-ui/icons/Star'
import { formatMonthYearDate } from '../../utils'
import { VerticalAlign } from '../vertical-align/VerticalAlign'
import { PaymentMethodPropType } from '../proptypes'
import { PaymentMethodMenu } from './PaymentMethodMenu'
import { PaymentMethodIcon } from './PaymentMethodIcon'

const useStyle = makeStyles(({ spacing }) => ({
  paymentMethod: {
    position: 'relative',
    height: 100,
  },
  cardDetails: {
    marginTop: -5,
  },
  cardMenuButton: {
    position: 'absolute',
    top: spacing(1),
    right: spacing(1),
  },
  defaultCardIcon: {
    marginRight: 2,
    marginBottom: -2,
  },
}))

export const PaymentMethod = (props) => {
  const { organizationId, paymentMethod } = props
  const classes = useStyle()

  const renderExpirationData = () =>
    formatMonthYearDate(paymentMethod.expirationMonth, paymentMethod.expirationYear)

  return (
    <Card className={classes.paymentMethod}>
      <CardContent>
        <div className={classes.cardMenuButton}>
          <PaymentMethodMenu organizationId={organizationId} paymentMethod={paymentMethod} />
        </div>

        <VerticalAlign>
          <Grid item>
            <Box marginRight={1}>
              <PaymentMethodIcon paymentMethod={paymentMethod} />
            </Box>
          </Grid>

          <Grid item className={classes.cardDetails}>
            <Typography variant="body2">
              {paymentMethod.isDefault && (
                <Tooltip title="This payment method will be used when charging for subscriptions">
                  <StarIcon
                    fontSize="inherit"
                    color="primary"
                    className={classes.defaultCardIcon}
                  />
                </Tooltip>
              )}
              {paymentMethod.brand.toUpperCase()} {paymentMethod.lastFour}
            </Typography>

            <Typography variant="caption" color="textSecondary">
              Expires {renderExpirationData()}
            </Typography>
          </Grid>
        </VerticalAlign>
      </CardContent>
    </Card>
  )
}

PaymentMethod.propTypes = {
  organizationId: PropTypes.string.isRequired,
  paymentMethod: PaymentMethodPropType,
}

import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { useAuth } from '../../hooks/useAuth'
import { useNotifications } from '../../hooks/useNotifications'
import { sendEmailVerification } from '../../services/auth.io'
import { Alert } from '../Alerts'

export const VerifyEmailBanner = () => {
  const [user] = useAuth()
  const { showInfo, showError } = useNotifications()

  const handleVerify = () => {
    sendEmailVerification()
      .then(() => showInfo('Email verification successfully sent'))
      .catch(({ message }) => showError(message))
  }

  return (
    <Alert
      show={!user.emailVerified}
      title="Email not verified"
      action={
        <Button size="small" color="secondary" variant="outlined" onClick={handleVerify}>
          Verify
        </Button>
      }
      message={
        <Typography>
          Heads Up! You haven't verified your email yet. It's required to start any builds.
        </Typography>
      }
      dismissible={false}
      severity="warning"
    />
  )
}

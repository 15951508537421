import { LoginPage } from './pages/login/LoginPage'
import { SignupPage } from './pages/signup/SignupPage'
import { ForgotPasswordPage } from './pages/forgot-password/ForgotPasswordPage'
import { TermsOfUse } from './pages/terms-of-use/TermsOfUse'
import { PrivacyPolicy } from './pages/privacy-policy/PrivacyPolicy'
import { OAuthCallback } from './components/oauth/OAuthCallback'
import { ComingSoon } from './pages/coming-soon/ComingSoon'
import { Dashboard } from './pages/dashboard/Dashboard'
import { NotFound404Page } from './pages/404/404'

export const routes = [
  {
    element: LoginPage,
    path: '/login',
    type: 'pre',
  },
  {
    element: SignupPage,
    path: '/signup',
  },
  {
    element: ForgotPasswordPage,
    path: '/forgot-password',
  },
  {
    element: TermsOfUse,
    path: '/terms-of-use',
  },
  {
    element: PrivacyPolicy,
    path: '/privacy-policy',
  },
  {
    element: OAuthCallback,
    path: '/oauth/callback',
    exact: true,
  },
  {
    element: ComingSoon,
    path: '/coming-soon',
  },
  {
    element: Dashboard,
    path: '/*',
    type: 'private',
  },
  {
    element: NotFound404Page,
    path: '*',
  },
]

import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { useOpenable } from '../../hooks/useOpenable'
import { CreatePaymentMethodModal } from './create-modal/CreatePaymentMethodModal'
import { Placeholder } from '../placeholder/Placeholder'
import { PaymentMethodPropType } from '../proptypes'
import { PaymentMethod } from './PaymentMethod'

import CardsImage from '../../assets/illustrations/credit_card.svg'

const useStyle = makeStyles(({ palette, spacing, shape }) => ({
  addCardButton: {
    color: palette.primary.divider,
    border: `1px ${palette.divider} dashed`,
    padding: spacing(2, 3),
    height: 100,
    width: '100%',
    transition: 0,
    '&:hover': {
      color: palette.primary.main,
      border: `1px ${palette.primary.main} solid`,
      backgroundColor: 'transparent',
    },
  },
}))

export const PaymentMethods = (props) => {
  const { organizationId, paymentMethods } = props

  const classes = useStyle()
  const { open, close, isOpen } = useOpenable()

  return (
    <Grid container spacing={2}>
      {!paymentMethods?.length && (
        <Grid item xs={12}>
          <Placeholder
            imageSrc={CardsImage}
            imageAlt="No Credit Cards"
            title="No Credit Cards"
            description={
              <>
                <Typography variant="body1" component="div" gutterBottom>
                  Add a payment method to be able to subscribe
                </Typography>

                <Button onClick={open} variant="contained" color="primary" size="large">
                  Add Payment Method
                </Button>
              </>
            }
          />
        </Grid>
      )}

      {paymentMethods?.map((paymentMethod) => (
        <Grid key={paymentMethod.id} item xs={12} md={3}>
          <PaymentMethod organizationId={organizationId} paymentMethod={paymentMethod} />
        </Grid>
      ))}

      {paymentMethods?.length > 0 && (
        <Grid item xs={12} md={3}>
          <Button onClick={open} variant="outlined" className={classes.addCardButton}>
            Add Payment Method
          </Button>
        </Grid>
      )}

      {/* isOpen so it reloads the stripe intent */}
      {isOpen && (
        <CreatePaymentMethodModal organizationId={organizationId} open={isOpen} onClose={close} />
      )}
    </Grid>
  )
}

PaymentMethods.propTypes = {
  organizationId: PropTypes.string.isRequired,
  paymentMethods: PropTypes.arrayOf(PaymentMethodPropType),
}

PaymentMethods.defaultProps = {
  paymentMethods: [],
}

import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import MuiLink from '@material-ui/core/Link'

const useStyles = makeStyles({
  link: {
    display: 'flex',
  },
})

export const Link = (props) => {
  const { to, label, icon, ...rest } = props
  const classes = useStyles()

  return (
    <MuiLink to={to} component={NavLink} className={classes.link} {...rest}>
      {icon}
      {label}
    </MuiLink>
  )
}

Link.propTypes = {
  to: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.node,
}

Link.defaultProps = {
  icon: null,
}

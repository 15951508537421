import { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import parseISO from 'date-fns/parseISO'
import sortBy from 'lodash/sortBy'
import List from '@material-ui/core/List'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import { Placeholder } from '../../../components/placeholder/Placeholder'
import { useAuth } from '../../../hooks/useAuth'
import { isEntityAdmin } from '../../../utils'
import { CreateProjectModal } from './CreateProjectModal'
import { ProjectListItem } from './ProjectListItem'
import { Alert } from '../../../components/Alerts'
import { Spinner } from '../../../components/Reusable'
import { useProjects } from '../../../services/projects/projects.queries'

import NoProjectsImage from '../../../assets/illustrations/old_day_arcade.svg'

export const OrgProjectList = (props) => {
  const { organization } = props
  const [user] = useAuth()
  const [isCreateModalOpen, setCreateModalOpen] = useState(false)
  const { data: projects, isLoading, error } = useProjects(organization.id)

  const isOrgAdmin = useMemo(() => isEntityAdmin(user?.id, organization), [user, organization])

  const sortedProjects = useMemo(
    () => sortBy(projects, ({ createdAt }) => -parseISO(createdAt).valueOf()),
    [projects],
  )

  if (isLoading) {
    return <Spinner isLoading={isLoading} />
  }

  return (
    <Card>
      <CardHeader
        title="Org Projects"
        action={
          <CardActions disableSpacing>
            <Button
              color="primary"
              variant="contained"
              size="small"
              disabled={!isOrgAdmin}
              onClick={() => setCreateModalOpen(true)}
            >
              + Add New Project
            </Button>
          </CardActions>
        }
      />

      <CardContent>
        <Alert show={!!error} message={error} severity="error" />

        <List>
          {!sortedProjects.length && (
            <Placeholder
              imageSrc={NoProjectsImage}
              imageAlt="No Projects"
              title="Add Your First Project"
              description="You will be able to connect your project to your Version Control system to build your game."
            />
          )}

          {sortedProjects.map((project) => (
            <ProjectListItem key={project.id} project={project} isOrgAdmin={isOrgAdmin} />
          ))}
        </List>
      </CardContent>

      <CreateProjectModal
        organizationId={organization.id}
        open={isCreateModalOpen}
        onClose={() => setCreateModalOpen(false)}
      />
    </Card>
  )
}

OrgProjectList.propTypes = {
  organization: PropTypes.object.isRequired,
}

import { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import keyBy from 'lodash/keyBy'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import { Plan } from './Plan'
import { Addon } from './Addon'
import { NoPaymentMethodWarning } from './NoPaymentMethodWarning'
import { UpdateSubscription } from './UpdateSubscribtion'
import { PlanPropType, SubscriptionPropType } from '../proptypes'
import { formatSubscriptionAsRequest } from '../../utils'

export const Subscriptions = (props) => {
  const { organizationId, paymentMethods, subscription, plans: allPlans } = props

  const subscriptionAsRequest = useMemo(
    () => formatSubscriptionAsRequest(subscription),
    [subscription],
  )
  const [subscriptionRequest, setSubscriptionRequest] = useState(subscriptionAsRequest)

  // plans/addons' id is their priceId
  const plans = useMemo(() => allPlans.filter((plan) => plan.type === 'plan'), [allPlans])
  const addons = useMemo(() => allPlans.filter((plan) => plan.type === 'addon'), [allPlans])

  const subscriptionItemsByPriceId = useMemo(
    () => keyBy(subscription?.items, 'priceId'),
    [subscription],
  )
  const onPlanChange = (priceId) => setSubscriptionRequest((req) => ({ ...req, plan: priceId }))
  const onAddonChange = (priceId, quantity) =>
    setSubscriptionRequest((req) => ({
      ...req,
      addons: { ...req.addons, [priceId]: quantity },
    }))

  return (
    <>
      <NoPaymentMethodWarning paymentMethods={paymentMethods} />

      <Typography variant="subtitle2" gutterBottom>
        Plans
      </Typography>

      <Grid container spacing={2}>
        {plans.map((plan) => (
          <Grid key={plan.id} item xs={12} md={6}>
            <Plan
              plan={plan}
              subscriptionItem={subscriptionItemsByPriceId[plan.id]}
              /* if selected, OR if subscription exists AND nothing selected yet */
              selected={
                subscriptionRequest.plan === plan.id ||
                (!!subscriptionItemsByPriceId[plan.id] && !subscriptionRequest.plan)
              }
              onChange={onPlanChange}
            />
          </Grid>
        ))}
      </Grid>

      <Box my={2} />

      <Typography variant="subtitle2" gutterBottom>
        Addons
      </Typography>

      <Grid container spacing={2}>
        {addons.map((addon) => (
          <Grid key={addon.id} item xs={12} md={4}>
            <Addon
              plan={addon}
              subscriptionItem={subscriptionItemsByPriceId[addon.id]}
              onChange={(quantity) => onAddonChange(addon.id, quantity)}
            />
          </Grid>
        ))}

        <Grid item xs={12}>
          <Divider style={{ marginBottom: 16 }} />
          <UpdateSubscription
            organizationId={organizationId}
            subscription={subscription}
            subscriptionRequest={subscriptionRequest}
            paymentMethods={paymentMethods}
          />
        </Grid>
      </Grid>
    </>
  )
}

Subscriptions.propTypes = {
  organizationId: PropTypes.string.isRequired,
  paymentMethods: PropTypes.array.isRequired,
  plans: PropTypes.arrayOf(PlanPropType),
  subscription: SubscriptionPropType,
}

Subscriptions.defaultProps = {
  plans: [],
  subscription: null,
}

import { PaymentMethodPropType } from '../proptypes'

export const PaymentMethodIcon = (props) => {
  const { paymentMethod } = props

  const fileName = paymentMethod.brand.toLowerCase().replace(/\s/g, '-')

  const getCardIcon = () => {
    try {
      return require(`./images/${fileName}.svg`)
    } catch (e) {
      return require('./images/unknown.svg').default
    }
  }

  return <img width={64} src={getCardIcon()} alt={paymentMethod.brand} />
}

PaymentMethodIcon.propTypes = {
  paymentMethod: PaymentMethodPropType,
}

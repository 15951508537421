import isEqual from 'lodash/isEqual'
import { Spinner } from './components/Reusable'
import { useAuth } from './hooks/useAuth'
import { Router } from './components/routers/Router'
import { routes } from './routes'

export const App = () => {
  const [user] = useAuth()

  // `{}` is initial value
  // logged out user is `null`
  if (isEqual(user, {})) {
    return <Spinner isLoading />
  }

  return <Router routes={routes} />
}

import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import SvgIcon from '@material-ui/core/SvgIcon'
import Tooltip from '@material-ui/core/Tooltip'
import { useBuildMetadata } from '../../services/builds/builds.queries'
import { useNotifications } from '../../hooks/useNotifications'

import { ReactComponent as BuildkiteLogo } from '../../assets/images/buildkite-logo.svg'

export const BuildListItemBuildkiteIcon = (props) => {
  const { organization, project, build } = props

  const { showWarning } = useNotifications()
  const { refetch: fetchMetadata, isFetching } = useBuildMetadata(
    organization.id,
    project.id,
    build.id,
    { enabled: false },
  )

  const handleOnClick = async () => {
    const { data: metadata } = await fetchMetadata()

    if (metadata.bkUrl) {
      window.open(metadata.bkUrl, '_blank')
    } else {
      showWarning('No Buildkite build')
    }
  }

  return (
    <Tooltip title="Go to Buildkite build">
      <span>
        <IconButton onClick={handleOnClick} disabled={isFetching}>
          <SvgIcon>
            <BuildkiteLogo />
          </SvgIcon>
        </IconButton>
      </span>
    </Tooltip>
  )
}

BuildListItemBuildkiteIcon.propTypes = {
  build: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
}

import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import TextField from '@material-ui/core/TextField'
import FormHelperText from '@material-ui/core/FormHelperText'
import { useCreateOrganization } from '../../services/organizations/organizations.queries'
import { useNotifications } from '../../hooks/useNotifications'
import { ErrorText } from '../../components/Reusable'

const schema = Yup.object({
  name: Yup.string().min(3).required().label('Name'),
})

export const CreateOrgModal = (props) => {
  const { isOpen, onClose } = props

  const { mutateAsync: createOrganization } = useCreateOrganization({
    onSuccess: () => showSuccess('Organization successfully created'),
    onError: ({ message }) => showError(message),
  })

  const { showSuccess, showError } = useNotifications()
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, isValid, errors },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    shouldFocusError: false,
    defaultValues: {
      name: '',
    },
  })

  const submit = async ({ name }) => {
    await createOrganization({ name })
    onClose()
  }

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth>
      <DialogTitle>{'Create New Organization'}</DialogTitle>

      <DialogContent>
        <TextField
          {...register('name')}
          name="name"
          label="Name"
          variant="outlined"
          fullWidth
          autoFocus
        />
        <ErrorText>{errors.name?.message}</ErrorText>
        <FormHelperText>An organization name must be unique among yours.</FormHelperText>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={isSubmitting || !isValid}
          onClick={handleSubmit(submit)}
        >
          {isSubmitting ? 'Creating...' : 'Create'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

CreateOrgModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

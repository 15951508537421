import { useParams } from 'react-router-dom'
import { makeStyles, ThemeProvider } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import Drawer from '@material-ui/core/Drawer'
import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import WidgetsIcon from '@material-ui/icons/WidgetsOutlined'
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked'
import GroupWorkIcon from '@material-ui/icons/GroupWorkOutlined'
import SettingsIcon from '@material-ui/icons/SettingsOutlined'
import CreditCardIcon from '@material-ui/icons/CreditCardOutlined'
import { NavLink } from '../routers/NavLink'
import { ProfileMenu } from './ProfileMenu'
import { UCloudLogo, UnrealCloudLogo } from '../logo/Logo'
import { darkTheme } from '../../theme'

const useStyles = makeStyles(({ palette, mixins, spacing, props }) => ({
  drawer: {
    width: props.drawer.width,
    flexShrink: 0,
  },
  drawerPaper: {
    width: props.drawer.width,
    background: palette.common.black,
  },
  // necessary for content to be below app bar
  toolbar: {
    ...mixins.toolbar,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 20,
  },
  uLogo: {
    height: 40,
    marginLeft: spacing(-1),
  },
  unrealCloudLogo: {
    height: 18,
    marginLeft: spacing(2),
  },
  list: {
    padding: 0,
  },
  listItem: {
    padding: 15,
  },
  activeListItem: {
    color: palette.primary.main,
    borderLeft: `5px solid ${palette.primary.main}`,
    paddingLeft: 10,
    '& svg': {
      color: palette.primary.main,
    },
  },
  listIcon: {
    marginLeft: spacing(1),
    color: palette.text.icon,
  },
  listText: {
    marginLeft: spacing(-1),
  },
  profileListItem: {
    position: 'fixed',
    bottom: 0,
  },
}))

export const Sidebar = () => {
  const classes = useStyles()

  const { organizationId, projectId } = useParams()

  return (
    <ThemeProvider theme={darkTheme}>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
      >
        <div className={classes.toolbar}>
          <UCloudLogo className={classes.uLogo} />
          <UnrealCloudLogo className={classes.unrealCloudLogo} />
        </div>

        <Divider />

        {organizationId && projectId && (
          <>
            <List className={classes.list}>
              <ListItem
                component={NavLink}
                to={`/organizations/${organizationId}/projects/${projectId}/builds`}
                className={classes.listItem}
                button
              >
                <ListItemIcon className={classes.listIcon}>
                  <WidgetsIcon />
                </ListItemIcon>
                <ListItemText primary={'Builds'} className={classes.listText} />
              </ListItem>

              <ListItem
                component={NavLink}
                to={`/organizations/${organizationId}/projects/${projectId}/targets`}
                className={classes.listItem}
                button
              >
                <ListItemIcon className={classes.listIcon}>
                  <RadioButtonCheckedIcon />
                </ListItemIcon>
                <ListItemText primary={'Targets'} className={classes.listText} />
              </ListItem>
            </List>

            <Divider />
          </>
        )}

        <List className={classes.list}>
          <ListItem component={NavLink} className={classes.listItem} to="/organizations" button>
            <ListItemIcon className={classes.listIcon}>
              <GroupWorkIcon />
            </ListItemIcon>
            <ListItemText primary={'Organizations'} className={classes.listText} />
          </ListItem>

          <ListItem className={classes.listItem} button>
            <ListItemIcon className={classes.listIcon}>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary={'Settings'} className={classes.listText} />
          </ListItem>
        </List>

        {organizationId && (
          <>
            <Divider />

            <List className={classes.list}>
              <ListItem
                component={NavLink}
                to={`/organizations/${organizationId}/billing`}
                className={classes.listItem}
                button
              >
                <ListItemIcon className={classes.listIcon}>
                  <CreditCardIcon />
                </ListItemIcon>
                <ListItemText primary={'Billing'} className={classes.listText} />
              </ListItem>
            </List>
          </>
        )}

        <ProfileMenu />
      </Drawer>
    </ThemeProvider>
  )
}

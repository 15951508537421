import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import find from 'lodash/find'
import filter from 'lodash/filter'
import reduce from 'lodash/reduce'
import pickBy from 'lodash/pickBy'

const getOrgMemberByUserId = (userId, entity) => find(entity?.members, (m) => m.userId === userId)

export const isUnrealCloudUser = (user) => user?.email.endsWith('@unrealcloud.io')

export const isMemberAdmin = (member) => member?.role === 'admin'

export const isEntityAdmin = (userId, entity) => {
  const member = getOrgMemberByUserId(userId, entity)
  return isMemberAdmin(member)
}

export const isBuildFinished = (status) =>
  ['passed', 'failed', 'canceled', 'cancelled'].includes(status)

export const genRandomString = () => Math.random().toString(36).substring(2, 15)

export const formatDate = (date) => format(parseISO(date), 'LLLL do, yyyy')

export const formatMonthYearDate = (month, year) => {
  const formattedMonth = month.toString().length === 1 ? `0${month}` : month
  const formattedYear = year.toString().slice(-2)
  return `${formattedMonth}/${formattedYear}`
}

export const formatCentsToDollars = (cents, currency = 'USD') => {
  const dollars = cents / 100
  return dollars.toLocaleString('en-US', {
    currency,
    style: 'currency',
  })
}

export const booleanToHuman = (bool) => (Boolean(bool) ? 'Yes' : 'No')

export const normalizeSubscriptionRequest = (subscription) => ({
  plan: subscription.plan ?? null,
  addons: pickBy(subscription.addons, (quantity) => quantity > 0),
})

export const formatSubscriptionAsRequest = (subscription) => {
  const subscriptionPlan = find(subscription?.items, (item) => item.type === 'plan')
  const subscriptionAddons = filter(subscription?.items, (item) => item.type === 'addon')

  return {
    plan: subscriptionPlan?.priceId ?? null,
    addons: reduce(
      subscriptionAddons,
      (acc, addon) => ({
        ...acc,
        [addon.priceId]: addon.quantity,
      }),
      {},
    ),
  }
}

import { makeStyles } from '@material-ui/core/styles'

export const useAnimationStyles = makeStyles({
  spin: {
    animation: '$spin 1s infinite linear',
  },
  '@keyframes spin': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(359deg)',
    },
  },
})

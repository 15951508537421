import { useSnackbar } from 'notistack'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

export const useNotifications = (defaultOpts = {}) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const notify =
    (variant) =>
    (message, opts = {}) =>
      enqueueSnackbar(message, {
        variant,
        action: (key) => (
          <IconButton size="small" onClick={() => closeSnackbar(key)}>
            <CloseIcon fontSize="inherit" color="inherit" />
          </IconButton>
        ),
        ...defaultOpts,
        ...opts,
      })

  return {
    showSuccess: notify('success'),
    showInfo: notify('info'),
    showWarning: notify('warning'),
    showError: notify('error'),
  }
}

import { apiAxios } from '../../libs/axios'

export const getOrganizations = () => apiAxios.get('/v1/organizations')

export const getOrganization = (organizationId) =>
  apiAxios.get(`/v1/organizations/${organizationId}`)

export const getOrganizationMembers = (organizationId) =>
  apiAxios.get(`/v1/organizations/${organizationId}/members`)

export const getOrganizationPendingMembers = (organizationId) =>
  apiAxios.get(`/v1/organizations/${organizationId}/invitations`)

export const inviteMember = (organizationId, { email, role }) =>
  apiAxios.post(`/v1/organizations/${organizationId}/members/invite`, {
    email,
    role,
  })

export const updateMemberRole = (organizationId, memberId, { role }) =>
  apiAxios.put(`/v1/organizations/${organizationId}/members/${memberId}`, { role })

export const removeMember = (organizationId, memberId) =>
  apiAxios.delete(`/v1/organizations/${organizationId}/members/${memberId}`)

export const removeInvitation = (organizationId, invitationId) =>
  apiAxios.delete(`/v1/organizations/${organizationId}/invitations/${invitationId}`)

export const createOrganization = ({ name }) => apiAxios.post('/v1/organizations', { name })

export const deleteOrganization = (organizationId) =>
  apiAxios.delete(`/v1/organizations/${organizationId}`)

import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import FormHelperText from '@material-ui/core/FormHelperText'
import TextField from '@material-ui/core/TextField'
import { useCreateProject } from '../../../services/projects/projects.queries'
import { useNotifications } from '../../../hooks/useNotifications'
import { ErrorText } from '../../../components/Reusable'

const schema = Yup.object({
  organizationId: Yup.string().required().label('Organization'),
  name: Yup.string().min(3).required().label('Name'),
})

export const CreateProjectModal = (props) => {
  const { organizationId, open, onClose } = props

  const { showSuccess, showError } = useNotifications()
  const { mutateAsync: createProject } = useCreateProject(organizationId, {
    onSuccess: () => showSuccess('Project successfully created'),
    onError: ({ message }) => showError(message),
  })

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, isValid, errors },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    shouldFocusError: false,
    defaultValues: {
      name: '',
      organizationId,
    },
  })

  const submit = async ({ name }) => {
    await createProject({ name })
    onClose()
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>{'Create New Project'}</DialogTitle>
      <DialogContent>
        <TextField
          {...register('name')}
          name="name"
          label="Name"
          variant="outlined"
          autoFocus
          fullWidth
        />
        <ErrorText>{errors.name?.message}</ErrorText>
        <FormHelperText>A project name but be unique in the organization.</FormHelperText>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={isSubmitting || !isValid}
          onClick={handleSubmit(submit)}
        >
          {isSubmitting ? 'Creating...' : 'Create'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

CreateProjectModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  organizationId: PropTypes.string,
}

CreateProjectModal.defaultProps = {
  organizationId: '',
}

import PropTypes from 'prop-types'
import upperFirst from 'lodash/upperFirst'
import { lighten, withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import MuiAvatar from '@material-ui/core/Avatar'
import CheckIcon from '@material-ui/icons/Check'
import ClearIcon from '@material-ui/icons/Clear'
import LoopIcon from '@material-ui/icons/Loop'
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty'
import PauseIcon from '@material-ui/icons/Pause'
import RemoveOutlinedIcon from '@material-ui/icons/RemoveOutlined'
import { useAnimationStyles } from '../../utils/classes'

const Avatar = withStyles(({ palette }) => ({
  root: {
    color: ({ status }) => palette.status[status] || palette.status.default,
    backgroundColor: ({ status }) => lighten(palette.status[status] || palette.status.default, 0.9),
  },
}))(MuiAvatar)

export const BuildIcon = (props) => {
  const { status } = props

  const animClasses = useAnimationStyles()

  const renderIcon = (status) => {
    switch (status) {
      case 'queued':
      case 'scheduled':
        return <HourglassEmptyIcon />
      case 'pending':
      case 'started':
        return <LoopIcon />
      case 'checkout':
      case 'building':
        return <LoopIcon className={animClasses.spin} />
      case 'passed':
        return <CheckIcon />
      case 'paused':
        return <PauseIcon />
      case 'canceled':
      case 'cancelled':
        return <RemoveOutlinedIcon />
      case 'failed':
        return <ClearIcon />
      default:
        return <RemoveOutlinedIcon />
    }
  }

  return (
    <Tooltip title={upperFirst(status)}>
      <Avatar status={status}>{renderIcon(status)}</Avatar>
    </Tooltip>
  )
}

BuildIcon.propTypes = {
  status: PropTypes.string.isRequired,
}

import PropTypes from 'prop-types'
import parseISO from 'date-fns/parseISO'
import formatDistance from 'date-fns/formatDistanceStrict'
import Tooltip from '@material-ui/core/Tooltip'
import AccessAlarmIcon from '@material-ui/icons/AccessAlarmOutlined'
import { useInterval } from '../../hooks/useInterval'
import { VerticalAlign } from '../vertical-align/VerticalAlign'
import { isBuildFinished } from '../../utils/index'

export const BuildDuration = (props) => {
  const { build, hideIcon } = props

  useInterval()

  const duration =
    isBuildFinished(build.status) && build.finishedAt
      ? formatDistance(parseISO(build.finishedAt), parseISO(build.createdAt), {
          addSuffix: false,
          includeSeconds: true,
        })
      : 'in progress'

  return (
    <VerticalAlign>
      {!hideIcon && (
        <>
          <AccessAlarmIcon fontSize="inherit" />
          &nbsp;
        </>
      )}

      <Tooltip title="Duration: the full time since the build was created until it was finished">
        <span>{duration}</span>
      </Tooltip>
    </VerticalAlign>
  )
}

BuildDuration.propTypes = {
  build: PropTypes.object.isRequired,
  hideIcon: PropTypes.bool,
}

BuildDuration.defaultProps = {
  hideIcon: false,
}

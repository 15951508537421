import { apiAxios } from '../../libs/axios'

export const getBuilds = (organizationId, projectId) =>
  apiAxios.get(`/v1/organizations/${organizationId}/projects/${projectId}/builds`)

export const getBuildUsagesByProjectId = (organizationId, projectId) =>
  apiAxios.get(`/v1/organizations/${organizationId}/projects/${projectId}/builds/usages`)

export const getBuild = (organizationId, projectId, buildId) =>
  apiAxios.get(`/v1/organizations/${organizationId}/projects/${projectId}/builds/${buildId}`)

export const getBuildMetadata = (organizationId, projectId, buildId) =>
  apiAxios.get(
    `/v1/organizations/${organizationId}/projects/${projectId}/builds/${buildId}/metadata`,
  )

export const getBuildLog = (organizationId, projectId, buildId) =>
  apiAxios.get(`/v1/organizations/${organizationId}/projects/${projectId}/builds/${buildId}/log`)

export const getArtifactSignedUrl = (organizationId, projectId, buildId, artifact) =>
  apiAxios.get(
    `/v1/organizations/${organizationId}/projects/${projectId}/builds/${buildId}/artifacts/${artifact}`,
  )

export const createBuild = (organizationId, projectId, { targetId }) =>
  apiAxios.post(`/v1/organizations/${organizationId}/projects/${projectId}/builds`, { targetId })

export const cancelBuild = (organizationId, projectId, buildId) =>
  apiAxios.delete(
    `/v1/organizations/${organizationId}/projects/${projectId}/builds/${buildId}/cancel`,
  )

export const deleteBuild = (organizationId, projectId, buildId) =>
  apiAxios.delete(`/v1/organizations/${organizationId}/projects/${projectId}/builds/${buildId}`)

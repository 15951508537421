import PropTypes from 'prop-types'
import { useOrganizationPaymentMethods } from '../../../../services/billing/billing.queries'
import { PaymentMethods } from '../../../../components/payment-method/PaymentMethods'
import { Spinner } from '../../../../components/Reusable'

export const PaymentMethodsTab = (props) => {
  const { organizationId } = props

  const { data: paymentMethods, isLoading } = useOrganizationPaymentMethods(organizationId)

  if (isLoading) {
    return <Spinner isLoading />
  }

  return <PaymentMethods organizationId={organizationId} paymentMethods={paymentMethods} />
}

PaymentMethodsTab.propTypes = {
  organizationId: PropTypes.string.isRequired,
}

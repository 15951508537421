import { cloneElement } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import useScrollTrigger from '@material-ui/core/useScrollTrigger'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import { Sidebar } from '../sidebar/Sidebar'
import { VerifyEmailBanner } from '../verify-email-banner/VerifyEmailBanner'

const useStyles = makeStyles(({ palette, mixins, props }) => ({
  root: {
    display: 'flex',
  },
  app: {
    flex: 1,
  },
  appBar: {
    color: palette.common.black,
    fontSize: 16,
    fontWeight: 'bold',
    width: `calc(100% - ${props.drawer.width}px)`,
    marginLeft: props.drawer.width,
    background: palette.common.white,
    borderBottom: `1px solid ${palette.divider}`,
    paddingLeft: 10,
  },
  // necessary for content to be below app bar
  toolbar: {
    ...mixins.toolbar,
  },
}))

function ElevationScroll(props) {
  const { children } = props
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  })

  return cloneElement(children, {
    elevation: trigger ? 4 : 0,
  })
}

export const DashboardPage = (props) => {
  const { title, children } = props
  const classes = useStyles()

  return (
    <>
      <div className={classes.toolbar} />

      <ElevationScroll>
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>{title}</Toolbar>
        </AppBar>
      </ElevationScroll>

      <VerifyEmailBanner />

      <div className={classes.root}>
        <Sidebar />

        <div className={classes.app}>{children}</div>
      </div>
    </>
  )
}

DashboardPage.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
}

DashboardPage.defaultProps = {
  title: '',
}

import { arrayOf, bool, number, object, oneOf, shape, string } from 'prop-types'
import { SubscriptionStatus } from '../constants/SubscriptionStatus'

export const PaymentMethodPropType = shape({
  id: string.isRequired,
  brand: string.isRequired,
  lastFour: string.isRequired,
  expirationMonth: number.isRequired,
  expirationYear: number.isRequired,
  createdAt: string.isRequired,
  isDefault: bool.isRequired,
})

export const InvoicePropType = shape({
  id: string.isRequired,
  year: number.isRequired,
  month: number.isRequired,
  status: string.isRequired,
  currency: string.isRequired,
  total: number.isRequired,
  url: string.isRequired,
  downloadUrl: string.isRequired,
})

export const PlanPropType = shape({
  id: string.isRequired,
  name: string.isRequired,
  description: string.isRequired,
  currency: string.isRequired,
  price: number.isRequired,
  image: string.isRequired,
  type: string.isRequired,
  metadata: shape({
    type: string.isRequired,
  }),
})

export const SubscriptionItemPropType = shape({
  id: string.isRequired,
  active: bool.isRequired,
  priceId: string.isRequired,
  productId: string.isRequired,
  quantity: number.isRequired,
  type: string.isRequired,
  metadata: object.isRequired,
})

export const SubscriptionPropType = shape({
  id: string.isRequired,
  organizationId: string.isRequired,
  status: oneOf(Object.values(SubscriptionStatus)).isRequired,
  items: arrayOf(SubscriptionItemPropType).isRequired,
  metadata: object.isRequired,
})

import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Chip from '@material-ui/core/Chip'
import Tooltip from '@material-ui/core/Tooltip'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { CopyToClipboard } from '../../components/copy-to-clipboard/CopyToClipboard'
import { formatDate } from '../../utils'

const useStyles = makeStyles(({ spacing }) => ({
  roleChip: {
    margin: spacing(1),
  },
}))

export const OrgDetails = (props) => {
  const { organization, isOrgAdmin } = props

  const classes = useStyles()

  return (
    <Card>
      <CardHeader
        title="Org Details"
        action={
          isOrgAdmin && (
            <Tooltip title="You are an admin of this org">
              <Chip
                size="small"
                label="ADMIN"
                color="secondary"
                variant="outlined"
                className={classes.roleChip}
              />
            </Tooltip>
          )
        }
      />

      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Typography variant="caption">Name</Typography>
            <Typography variant="body1">{organization.name}</Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography variant="caption">Created At</Typography>
            <Typography variant="body1">{formatDate(organization.createdAt)}</Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography variant="caption">Org ID</Typography>
            <Typography variant="body1">
              {organization.id}
              <CopyToClipboard text={organization.id} />
            </Typography>
          </Grid>

          {organization.updatedAt !== organization.createdAt && (
            <Grid item xs={12} sm={6}>
              <Typography variant="caption">Updated At</Typography>
              <Typography variant="body1">{formatDate(organization.updatedAt)}</Typography>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  )
}

OrgDetails.propTypes = {
  organization: PropTypes.object.isRequired,
  isOrgAdmin: PropTypes.bool.isRequired,
}

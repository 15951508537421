import { useEffect } from 'react'
import { useQueryParams } from '../../hooks/useQueryParams'
import { Spinner } from '../Reusable'
import { appHost } from '../../config'

export const OAuthCallback = () => {
  const [queryParams] = useQueryParams()

  useEffect(() => {
    window.opener?.postMessage(queryParams, appHost)

    // prevent CSS styles from breaking when closed too fast
    setTimeout(() => window.close(), 500)
  }, [queryParams])

  return <Spinner isLoading />
}

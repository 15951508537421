import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import parseISO from 'date-fns/parseISO'
import { makeStyles } from '@material-ui/core/styles'
import DeleteIcon from '@material-ui/icons/DeleteOutlined'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import { useConfirm } from 'material-ui-confirm'
import { ListItemSecondaryAction } from '@material-ui/core'
import { Avatar } from '../../components/avatar/Avatar'
import { useDeleteOrganization } from '../../services/organizations/organizations.queries'
import { useNotifications } from '../../hooks/useNotifications'
import { useAuth } from '../../hooks/useAuth'
import { isEntityAdmin } from '../../utils'

const useStyles = makeStyles(({ palette }) => ({
  deleteButton: {
    color: palette.error.main,
  },
  deleteButtonIcon: {
    '&:hover': {
      color: palette.error.main,
    },
  },
}))

export const OrganizationListItem = (props) => {
  const { org } = props

  const classes = useStyles()
  const [user] = useAuth()
  const confirm = useConfirm()
  const { showSuccess, showError } = useNotifications()
  const { mutateAsync: deleteOrganization } = useDeleteOrganization(org.id, {
    onSuccess: () => showSuccess('Organization successfully deleted'),
    onError: ({ message }) => showError(message),
  })

  const isOrgAdmin = useMemo(() => isEntityAdmin(user?.id, org), [user, org])

  const handleDelete = (organizationId) => {
    confirm({
      description: 'This action is permanent!',
      dialogProps: { maxWidth: 'xs' },
      confirmationText: 'Delete',
      confirmationButtonProps: { className: classes.deleteButton },
    })
      .then(() => deleteOrganization(organizationId))
      .catch(() => {
        // do nothing
      })
  }

  return (
    <ListItem key={org.id} button component={Link} to={`/organizations/${org.id}`}>
      <ListItemAvatar>
        <Avatar user={org} />
      </ListItemAvatar>

      <ListItemText
        primary={org.name}
        secondary={`Created ${formatDistanceToNow(parseISO(org.createdAt), {
          addSuffix: true,
        })}`}
      />

      <ListItemSecondaryAction>
        <Tooltip
          title="You need to be an org admin to delete it"
          disableHoverListener={isOrgAdmin}
          disableFocusListener={isOrgAdmin}
          disableTouchListener={isOrgAdmin}
        >
          <span>
            <IconButton
              className={classes.deleteButtonIcon}
              onClick={() => handleDelete(org.id)}
              disabled={!isOrgAdmin}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </span>
        </Tooltip>
      </ListItemSecondaryAction>
    </ListItem>
  )
}

OrganizationListItem.propTypes = {
  org: PropTypes.object.isRequired,
}

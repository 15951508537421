import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import upperFirst from 'lodash/upperFirst'
import isString from 'lodash/isString'
import { makeStyles } from '@material-ui/core/styles'
import MuiAlert from '@material-ui/lab/Alert'
import MuiAlertTitle from '@material-ui/lab/AlertTitle'

const useStyles = makeStyles(({ spacing }) => ({
  alert: {
    marginBottom: spacing(4),
  },
}))

export const Alert = ({ title, message, severity, dismissible, show, ...props }) => {
  const classes = useStyles()
  const [dismissed, setDismissed] = useState(show)

  // reset dismissed value
  useEffect(() => {
    setDismissed(!show)
  }, [show])

  return dismissed ? null : (
    <MuiAlert
      severity={severity}
      onClose={dismissible ? () => setDismissed(true) : null}
      className={classes.alert}
      {...props}
    >
      {title && <MuiAlertTitle>{upperFirst(title)}</MuiAlertTitle>}
      {isString(message) ? upperFirst(message) : message}
    </MuiAlert>
  )
}

Alert.propTypes = {
  title: PropTypes.string,
  message: PropTypes.node,
  dismissible: PropTypes.bool,
  show: PropTypes.bool,
  severity: PropTypes.oneOf(['success', 'info', 'warning', 'error']),
}

Alert.defaultProps = {
  title: '',
  message: '',
  dismissible: true,
  show: false,
  severity: 'info',
}

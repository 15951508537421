import { makeStyles } from '@material-ui/core/styles'
import { Router } from '../../components/routers/Router'
import { useAuth } from '../../hooks/useAuth'
import { routes } from './routes'

const useStyles = makeStyles(({ spacing }) => ({
  content: {
    flexGrow: 1,
    padding: spacing(4),
  },
}))

export const Dashboard = () => {
  const [user] = useAuth()
  const classes = useStyles()

  if (!user) {
    window.location.assign('/login')
    return null
  }

  return (
    <main className={classes.content}>
      <Router routes={routes} />
    </main>
  )
}

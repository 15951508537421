import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Paper from '@material-ui/core/Paper'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import VisibilityIcon from '@material-ui/icons/Visibility'
import GetAppIcon from '@material-ui/icons/GetApp'
import Chip from '@material-ui/core/Chip'
import Box from '@material-ui/core/Box'
import { InvoicePropType } from '../proptypes'
import { formatCentsToDollars, formatMonthYearDate } from '../../utils'
import { VerticalAlign } from '../vertical-align/VerticalAlign'
import { Placeholder } from '../placeholder/Placeholder'

import InvoicesImage from '../../assets/illustrations/invoices.svg'

const useStyles = makeStyles(({ palette }) => ({
  tableRow: {
    height: 80,
  },
  draft: {
    backgroundColor: palette.disabled,
  },
  open: {
    backgroundColor: palette.primary.main,
  },
  paid: {
    backgroundColor: palette.success.main,
  },
  uncollectible: {
    backgroundColor: palette.error.main,
  },
  void: {
    backgroundColor: palette.disabled,
  },
}))

export const InvoicesTable = (props) => {
  const { invoices } = props
  const classes = useStyles()

  const renderInvoiceDate = (invoice) => formatMonthYearDate(invoice.month, invoice.year)

  if (!invoices.length) {
    return (
      <Placeholder
        imageSrc={InvoicesImage}
        imageAlt="No Invoices"
        title="No Invoices"
        description="Invoices will appear here once you subscribe"
      />
    )
  }

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableBody>
          {invoices.map((invoice) => (
            <TableRow key={invoice.id} className={classes.tableRow} hover>
              <TableCell>
                <strong>{renderInvoiceDate(invoice)}</strong>
              </TableCell>

              <TableCell>
                <VerticalAlign>
                  <Box marginRight={1}>
                    <Chip
                      size="small"
                      label={invoice.status.toUpperCase()}
                      className={classes[invoice.status]}
                    />
                  </Box>
                </VerticalAlign>
              </TableCell>

              <TableCell>{formatCentsToDollars(invoice.total, invoice.currency)}</TableCell>

              <TableCell align="right">
                {invoice.url && (
                  <Tooltip title="View">
                    <IconButton component="a" target="_blank" href={invoice.url}>
                      <VisibilityIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                )}

                {invoice.downloadUrl && (
                  <Tooltip title="Download">
                    <IconButton component="a" target="_blank" href={invoice.downloadUrl}>
                      <GetAppIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

InvoicesTable.propTypes = {
  invoices: PropTypes.arrayOf(InvoicePropType).isRequired,
}

import PropTypes from 'prop-types'
import parseISO from 'date-fns/parseISO'
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict'
import Tooltip from '@material-ui/core/Tooltip'
import CalendarTodayIcon from '@material-ui/icons/CalendarTodayOutlined'
import { VerticalAlign } from '../vertical-align/VerticalAlign'
import { useInterval } from '../../hooks/useInterval'

export const BuildCreation = (props) => {
  const { build, hideIcon } = props

  useInterval()

  const sinceCreation = formatDistanceToNowStrict(parseISO(build.createdAt), {
    addSuffix: true,
    includeSeconds: true,
  })

  return (
    <VerticalAlign>
      {!hideIcon && (
        <>
          <CalendarTodayIcon fontSize="inherit" />
          &nbsp;
        </>
      )}

      <Tooltip title="Creation Time: the elapsed time since the build was created">
        <span>{sinceCreation}</span>
      </Tooltip>
    </VerticalAlign>
  )
}

BuildCreation.propTypes = {
  build: PropTypes.object.isRequired,
  hideIcon: PropTypes.bool,
}

BuildCreation.defaultProps = {
  hideIcon: false,
}

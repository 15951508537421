import PropTypes from 'prop-types'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import parseISO from 'date-fns/parseISO'
import { makeStyles } from '@material-ui/core/styles'
import DeleteIcon from '@material-ui/icons/DeleteOutlined'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import IconButton from '@material-ui/core/IconButton'
import { useConfirm } from 'material-ui-confirm'
import { ListItemSecondaryAction } from '@material-ui/core'
import { useNotifications } from '../../hooks/useNotifications'
import { useDeleteTarget } from '../../services/targets/targets.queries'

const useStyles = makeStyles(({ palette }) => ({
  deleteButton: {
    color: palette.error.main,
  },
  deleteButtonIcon: {
    '&:hover': {
      color: palette.error.main,
    },
  },
}))

export const TargetListItem = (props) => {
  const { organization, project, target } = props

  const classes = useStyles()
  const confirm = useConfirm()
  const { showSuccess, showError } = useNotifications()
  const { mutateAsync: deleteTarget } = useDeleteTarget(organization.id, project.id, target.id, {
    onSuccess: () => showSuccess('Target successfully deleted'),
    onError: ({ message }) => showError(message),
  })

  const handleDelete = () => {
    confirm({
      description: 'This action is permanent!',
      dialogProps: { maxWidth: 'xs' },
      confirmationText: 'Delete',
      confirmationButtonProps: { className: classes.deleteButton },
    })
      .then(deleteTarget)
      .catch(() => {
        // do nothing
      })
  }

  return (
    <ListItem>
      <ListItemText
        primary={target.name}
        secondary={`Created ${formatDistanceToNow(parseISO(target.createdAt), {
          addSuffix: true,
        })}`}
      />

      <ListItemSecondaryAction>
        <IconButton className={classes.deleteButtonIcon} onClick={handleDelete}>
          <DeleteIcon fontSize="small" />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  )
}

TargetListItem.propTypes = {
  organization: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
  target: PropTypes.object.isRequired,
}

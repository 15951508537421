import GithubIcon from '@material-ui/icons/GitHub'
import { githubClientId } from '../config'

export const ScmProviders = {
  github: {
    key: 'github',
    label: 'GitHub',
    revokeUrl: `https://github.com/settings/connections/applications/${githubClientId}`,
    icon: <GithubIcon />,
  },
  bitbucket: {
    key: 'bitbucket',
    label: 'Bitbucket',
    icon: null,
  },
  perforce: {
    key: 'perforce',
    label: 'Perforce',
    icon: null,
  },
}

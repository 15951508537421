import PropTypes from 'prop-types'

export const Log = (props) => {
  const { log } = props

  if (!log) {
    return null
  }

  return <div className="term-container" dangerouslySetInnerHTML={{ __html: log }} />
}

Log.propTypes = {
  log: PropTypes.string,
}

Log.defaultProps = {
  log: '',
}

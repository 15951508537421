import { useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import { CopyToClipboard as ReactCopyToClipBoard } from 'react-copy-to-clipboard'

const useStyles = makeStyles(({ palette, spacing }) => ({
  buttonIcon: {
    marginLeft: spacing(1),
  },
  icon: {
    color: palette.success.main,
  },
}))

const options = { format: 'text/plain' }

export const CopyToClipboard = (props) => {
  const { text } = props

  const classes = useStyles()
  const [isCopied, setIsCopied] = useState(false)

  const handleCopy = () => {
    setIsCopied(true)
    setTimeout(() => setIsCopied(false), 1500)
  }

  return (
    <ReactCopyToClipBoard options={options} onCopy={handleCopy} text={text}>
      <Tooltip placement="top" title={isCopied ? 'Copied!' : 'Copy'} arrow>
        <IconButton className={classes.buttonIcon}>
          <FileCopyIcon
            fontSize="small"
            className={clsx({
              [classes.icon]: isCopied,
            })}
          />
        </IconButton>
      </Tooltip>
    </ReactCopyToClipBoard>
  )
}

CopyToClipboard.propTypes = {
  text: PropTypes.string.isRequired,
}

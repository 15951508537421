import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import UserIcon from '@material-ui/icons/PersonOutlineOutlined'
import MinutesIcon from '@material-ui/icons/AccessTimeOutlined'
import ConcurrencyIcon from '@material-ui/icons/ClearAllOutlined'
import { VerticalAlign } from '../vertical-align/VerticalAlign'

const MetadataItem = (props) => {
  const { name, value, icon: Icon } = props

  return (
    <VerticalAlign display="flex" my={2}>
      <Icon color="inherit" fontSize="small" style={{ marginRight: 5 }} />
      <Typography variant="body2">
        {value} {name}
      </Typography>
    </VerticalAlign>
  )
}

export const Metadata = (props) => {
  const { metadata } = props
  const { users, minutes, concurrency } = metadata

  return (
    <>
      {users && <MetadataItem name="Users" value={users} icon={UserIcon} />}
      {minutes && <MetadataItem name="Build minutes" value={minutes} icon={MinutesIcon} />}
      {concurrency && (
        <MetadataItem name="Concurrent builds" value={concurrency} icon={ConcurrencyIcon} />
      )}
    </>
  )
}

Metadata.propTypes = {
  metadata: PropTypes.shape({
    users: PropTypes.string,
    minutes: PropTypes.string,
    concurrency: PropTypes.string,
  }),
}

Metadata.defaultProps = {
  metadata: {
    users: null,
    minutes: null,
    concurrency: null,
  },
}

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Colors } from '../constants/Colors'

const styles = {
  page: {
    padding: '150px 0',
    minHeight: '100vh',
  },
  section: {
    container: {
      fontFamily: 'Gotham Rounded Light, Helvetica, sans-serif',
      color: Colors.dark.main,
      background: Colors.dark.light,
      borderRadius: '.25rem',
      boxShadow: '0 7px 14px 0 rgba(60,66,87, .12), 0 3px 6px 0 rgba(0,0,0, .12)',
      marginBottom: 20,
    },
    header: {
      borderBottom: `1px solid ${Colors.blue.light}`,
    },
    title: {
      fontFamily: 'Gotham Rounded Medium, Helvetica, sans-serif',
      fontWeight: 400,
      marginBottom: -5,
    },
    description: {},
    content: {
      padding: '15px 20px',
    },
  },
  errorText: {
    color: Colors.red.main,
  },
  spinnerContainer: {
    textAlign: 'center',
  },
  spinner: {
    marginTop: 50,
    marginBottom: 50,
    color: Colors.blue.light,
  },
}

export const Page = ({ children, ...props }) => (
  <div style={styles.page} {...props}>
    {children}
  </div>
)

export const SubmitButton = ({ children, ...props }) => (
  <Button type="submit" color="primary" variant="contained" {...props}>
    {children}
  </Button>
)

export const Spinner = ({ isLoading = false }) =>
  isLoading ? (
    <div style={styles.spinnerContainer}>
      <CircularProgress style={styles.spinner} color="primary" />
    </div>
  ) : null

export const Price = ({ price, currency }) => (
  <span>
    ${price.toFixed(2)} {currency.toUpperCase()}
  </span>
)

export const ErrorText = ({ children }) => <div style={styles.errorText}>{children}</div>

export const If = ({ condition, children }) => (condition ? children : null)

import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import TextField from '@material-ui/core/TextField'
import { loginSchema } from '../../components/validations'
import { ErrorText, SubmitButton } from '../../components/Reusable'
import { useAuth } from '../../hooks/useAuth'
import { useNotifications } from '../../hooks/useNotifications'
import { me, signIn, signOut } from '../../services/auth.io'

export const LoginForm = (props) => {
  const { onLogin } = props

  const [, setUser] = useAuth()
  const { showSuccess, showError } = useNotifications()
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, isValid, errors },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(loginSchema),
    shouldFocusError: false,
    defaultValues: {
      email: '',
      password: '',
    },
  })

  const submit = ({ email, password }) =>
    signIn(email, password)
      .then(() =>
        me()
          .then((user) => {
            setUser(user)
            showSuccess('Successfully authenticated')
            onLogin?.(user)
          })
          .catch(({ message }) => {
            showError(message)

            // to avoid being logged in with Firebase
            // but without our proper user object from me()
            return signOut()
          }),
      )
      .catch(({ message }) => showError(message))

  return (
    <>
      <TextField
        {...register('email')}
        name="email"
        type="email"
        label="Email"
        variant="outlined"
        fullWidth
        autoFocus
      />
      <ErrorText>{errors.email?.message}</ErrorText>

      <br />

      <TextField
        {...register('password')}
        name="password"
        type="password"
        label="Password"
        variant="outlined"
        fullWidth
      />
      <ErrorText>{errors.password?.message}</ErrorText>

      <br />

      <SubmitButton
        type="submit"
        disabled={!isValid || isSubmitting}
        onClick={handleSubmit(submit)}
        fullWidth
      >
        {isSubmitting ? 'Loading...' : 'Login'}
      </SubmitButton>
    </>
  )
}

LoginForm.propTypes = {
  onLogin: PropTypes.func,
}

LoginForm.defaultProps = {
  onLogin: null,
}

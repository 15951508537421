import { useState } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Divider from '@material-ui/core/Divider'
import { AddMemberModal } from './AddMemberModal'
import { Spinner } from '../../components/Reusable'
import { Alert } from '../../components/Alerts'
import { MemberList } from '../../components/member-list/MemberList'
import {
  useOrganizationMembers,
  useOrganizationPendingMembers,
} from '../../services/organizations/organizations.queries'
import { queryClient } from '../../libs/react-query'

export const OrgMembers = (props) => {
  const { isOrgAdmin, organizationId } = props

  const [isModalOpen, setIsModalOpen] = useState(false)

  const orgMembersReq = useOrganizationMembers(organizationId)
  const invitesReq = useOrganizationPendingMembers(organizationId)

  return (
    <Card>
      <CardHeader
        title="Org Members"
        action={
          <CardActions disableSpacing>
            <Button
              size="small"
              color="primary"
              variant="contained"
              disabled={!isOrgAdmin}
              onClick={() => setIsModalOpen(true)}
            >
              Invite Member
            </Button>
          </CardActions>
        }
      />

      <CardContent>
        <Spinner isLoading={orgMembersReq.isLoading || invitesReq.isLoading} />
        <Alert show={!!orgMembersReq.error} message={orgMembersReq.error} severity="error" />
        <Alert show={!!invitesReq.error} message={invitesReq.error} severity="error" />

        <MemberList
          members={orgMembersReq.data}
          organizationId={organizationId}
          isOrgAdmin={isOrgAdmin}
        />

        {!!invitesReq.data.length && <Divider />}

        <MemberList
          members={invitesReq.data}
          organizationId={organizationId}
          isOrgAdmin={isOrgAdmin}
        />
      </CardContent>

      <AddMemberModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        organizationId={organizationId}
      />
    </Card>
  )
}

OrgMembers.propTypes = {
  isOrgAdmin: PropTypes.bool.isRequired,
  organizationId: PropTypes.string.isRequired,
}

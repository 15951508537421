import axios from 'axios'
import upperFirst from 'lodash/upperFirst'
import { apiHost } from '../config'
import { getIdToken } from '../services/auth.io'

const extractError = (error) => ({
  message:
    error?.response?.data?.message ||
    error?.response?.data?.error ||
    error?.response?.message ||
    error?.response?.data ||
    error?.message ||
    'An error occurred. Please retry.',
})

export const apiAxios = axios.create({
  baseURL: apiHost,
})

apiAxios.interceptors.request.use(async (config) => ({
  ...config,
  headers: {
    ...config.headers,
    token: await getIdToken(false),
  },
}))

apiAxios.interceptors.response.use(
  (res) => res?.data,
  async (res) => {
    const isUnauthorized = res?.response?.status
    const request = res.config

    // refresh Firebase token and retry, only once
    if (isUnauthorized && !request._isUnauthorizedRetried) {
      const token = await getIdToken(true)
      request._isUnauthorizedRetried = true

      // re-add tokens to apiAxios and current request
      apiAxios.defaults.headers.Authorization = token
      request.headers.Authorization = token

      // retry
      return apiAxios.request(request)
    }

    const error = extractError(res)
    const message = upperFirst(error.message)

    return Promise.reject({ message })
  },
)

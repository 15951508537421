import { useState } from 'react'
import MailchimpSubscribe from 'react-mailchimp-subscribe'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import InputBase from '@material-ui/core/InputBase'
import Button from '@material-ui/core/Button'
import { Alert } from '../../components/Alerts'

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    display: 'flex',
    padding: '12px 12px 12px 14px',
    alignItems: 'center',
    backgroundColor: palette.common.white,
  },
  input: {
    flex: 1,
    marginLeft: spacing(1),
    color: palette.common.black,
    backgroundColor: palette.common.white,
  },
  button: {
    color: palette.common.white,
    padding: 10,
    backgroundColor: palette.primary.main,
    '&:hover': {
      backgroundColor: palette.primary.light,
    },
  },
  divider: {
    height: 28,
    margin: 4,
  },
  title: {
    color: palette.common.white,
    fontSize: 24,
    fontWeight: 'bold',
    fontFamily: 'Gotham Rounded Light, Helvetica Nueue, sans-serif',
  },
}))

export const IntroNewsletter = () => {
  const classes = useStyles()
  const [email, setEmail] = useState('')

  const handleEmailChange = (event) => {
    event.persist()
    setEmail(event.target.value)
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <div className={classes.title}>Get Notified on Launch</div>
      </Grid>

      <Grid item xs={12}>
        <MailchimpSubscribe
          url={
            'https://unrealcloud.us2.list-manage.com/subscribe/post?u=12a95ea7b118d6f425bdb2d7b&id=86169b757d'
          }
          render={({ subscribe, status, message }) => (
            <>
              <Box marginBottom={1}>
                <Alert
                  show={status === 'success'}
                  message={`Successfully subscribed ${email}`}
                  severity="success"
                />
                <Alert show={status === 'error'} message={message} severity="error" />
              </Box>

              <Paper
                component={'form'}
                className={classes.root}
                onSubmit={(event) => {
                  event.persist()
                  event.preventDefault()
                  subscribe({ EMAIL: email })
                }}
              >
                <InputBase
                  className={classes.input}
                  placeholder={'unrealuser@domain.com'}
                  inputProps={{ 'aria-label': 'email' }}
                  value={email}
                  onChange={handleEmailChange}
                />

                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  size="large"
                  className={classes.button}
                >
                  {status === 'sending' ? 'Subscribing...' : 'Subscribe'}
                </Button>
              </Paper>
            </>
          )}
        />
      </Grid>
    </Grid>
  )
}

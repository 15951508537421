import { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import CodeIcon from '@material-ui/icons/Code'
import NoSCMImage from '../../assets/illustrations/version_control.svg'
import { Placeholder } from '../../components/placeholder/Placeholder'
import { useNotifications } from '../../hooks/useNotifications'
import { useUpdateProject } from '../../services/projects/projects.queries'
import { useSCMProviders } from '../../services/scm/scm.queries'
import { RepoSelection } from './RepoSelection'

// TODO: show
export const ProjectSCM = (props) => {
  const { project } = props

  const [selectedScmProvider, setSelectedScmProvider] = useState()
  const { showSuccess, showError } = useNotifications()
  const { mutateAsync: updateProject } = useUpdateProject(project.organizationId, project.id, {
    onSuccess: () => showSuccess('Successfully updated project repository'),
    onError: ({ message }) => showError(message),
  })

  const { data } = useSCMProviders()

  const handleOnRepoSelect = async (repository) => {
    const payload = {
      scm: {
        repository,
        type: 'git', // TODO: from provider
        providerId: selectedScmProvider?.id,
      },
    }

    await updateProject({ payload })
    setSelectedScmProvider(null)
  }

  return (
    <Card>
      <CardHeader
        title="Project SCM"
        action={
          <CardActions>
            <CodeIcon fontSize="small" />
            <Typography variant="caption">
              <Link to="/scm-accounts">Click here to manage your SCM Accounts</Link>
            </Typography>
          </CardActions>
        }
      />

      <CardContent>
        {
          <Placeholder
            imageSrc={NoSCMImage}
            imageAlt="Select SCM"
            title="Select Your SCM"
            description={
              <>
                <Box marginBottom={2}>Specify where to find your project source code.</Box>

                {data
                  .filter((scmProvider) => scmProvider.hasAccess)
                  .map((scmProvider) => (
                    <Button
                      key={scmProvider.id}
                      variant="outlined"
                      onClick={() => setSelectedScmProvider(scmProvider)}
                    >
                      Github
                    </Button>
                  ))}
              </>
            }
          />
        }
      </CardContent>

      <RepoSelection
        repository={project.scm?.repository}
        scmProvider={selectedScmProvider}
        isOpen={Boolean(selectedScmProvider)}
        onSelect={handleOnRepoSelect}
        onClose={() => setSelectedScmProvider(null)}
      />
    </Card>
  )
}

ProjectSCM.propTypes = {
  project: PropTypes.object.isRequired,
}

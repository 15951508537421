import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { ErrorText } from '../../components/Reusable'
import { editPasswordSchema } from '../../components/validations'
import { useAuth } from '../../hooks/useAuth'
import { useNotifications } from '../../hooks/useNotifications'
import { updatePassword } from '../../services/auth.io'

export const EditPasswordModal = (props) => {
  const { isOpen, onClose, onSave } = props

  const [user] = useAuth()
  const { showSuccess, showError } = useNotifications()
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, isValid, errors },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(editPasswordSchema),
    shouldFocusError: false,
    defaultValues: {
      password: '',
      passwordConfirm: '',
    },
  })

  const handleUpdatePassword = ({ password }) => {
    updatePassword({ password })
      .then(() => showSuccess('Password successfully updated.'))
      .then(() => onSave(user))
      .catch(({ message }) => showError(message))
  }

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth>
      <DialogTitle>Update your Password</DialogTitle>

      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              {...register('password')}
              name="password"
              type="password"
              label="New Password"
              variant="outlined"
              fullWidth
            />
            <ErrorText>{errors.password?.message}</ErrorText>
          </Grid>

          <Grid item xs={12}>
            <TextField
              {...register('passwordConfirm')}
              name="passwordConfirm"
              type="password"
              label="Confirm New Password"
              variant="outlined"
              fullWidth
            />
            <ErrorText>{errors.passwordConfirm?.message}</ErrorText>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <Button
          color="primary"
          disabled={!isValid || isSubmitting}
          onClick={handleSubmit(handleUpdatePassword)}
        >
          Update password
        </Button>
      </DialogActions>
    </Dialog>
  )
}

EditPasswordModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

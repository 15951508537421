import PropTypes from 'prop-types'
import { useTheme } from '@material-ui/core'
import { SVGLink } from '../../svg-link/SVGLink'

import { ReactComponent as Logo } from './uc-logo.svg'

export const BuildFavicon = (props) => {
  const { build } = props

  const { palette } = useTheme()

  const fill = palette.status[build?.status] || palette.primary.main

  return (
    <SVGLink>
      <Logo fill={fill} />
    </SVGLink>
  )
}

BuildFavicon.propTypes = {
  build: PropTypes.object.isRequired,
}

import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { getAnalytics } from 'firebase/analytics'
import { firebaseConfig } from '../config'

export const app = initializeApp(firebaseConfig)
export const firestore = getFirestore(app)
export const analytics = getAnalytics(app)

// auth
export const auth = getAuth(app)

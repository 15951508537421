import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'

export const VerticalAlign = (props) => {
  const { children, ...rest } = props

  return (
    <Box display="inline-flex" alignItems="center" component="span" {...rest}>
      {children}
    </Box>
  )
}

VerticalAlign.propTypes = {
  children: PropTypes.node.isRequired,
}

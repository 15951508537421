import { useForm } from 'react-hook-form'
import TextField from '@material-ui/core/TextField'
import { yupResolver } from '@hookform/resolvers/yup'
import { resetPasswordSchema } from '../../components/validations'
import { SubmitButton, ErrorText } from '../../components/Reusable'
import { useNotifications } from '../../hooks/useNotifications'
import { resetPassword } from '../../services/auth.io'

export const ResetPasswordForm = () => {
  const { showSuccess, showError } = useNotifications()
  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting, isValid, errors },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(resetPasswordSchema),
    shouldFocusError: false,
    defaultValues: {
      email: '',
    },
  })

  const submit = ({ email }) =>
    resetPassword(email)
      .then(() => showSuccess('Reset email has been sent'))
      .then(() => reset())
      .catch(({ message }) => showError(message))

  return (
    <>
      <TextField
        {...register('email')}
        name="email"
        type="email"
        label="Email"
        variant="outlined"
        fullWidth
        autoFocus
      />
      <ErrorText>{errors.email?.message}</ErrorText>

      <br />

      <SubmitButton
        type="submit"
        disabled={!isValid || isSubmitting}
        onClick={handleSubmit(submit)}
        fullWidth
      >
        {isSubmitting ? 'Loading...' : 'Reset Password'}
      </SubmitButton>
    </>
  )
}

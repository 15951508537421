import { useState } from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useNotifications } from '../../hooks/useNotifications'
import { useRepos } from '../../services/github/github.queries'
import { ScmProviders } from '../../constants/ScmProviders'

export const RepoSelection = (props) => {
  const { scmProvider, repository, onSelect, isOpen, onClose } = props

  const [isACOpen, setIsACOpen] = useState(false)
  const [options, setOptions] = useState([])
  const [selectedOption, setSelectedOption] = useState(repository)
  const { showError } = useNotifications()
  const { refetch: fetchRepos, isInitialLoading: isLoading } = useRepos(
    { page: 1, per_page: 100 },
    {
      onSuccess: (data) => setOptions(data.items),
      onError: ({ message }) => {
        if (message?.includes(401)) {
          showError('Unauthorized. You may need to reconnect your SCM account to Unreal Cloud')
        } else {
          showError(message)
        }
      },
    },
  )

  const handleOpen = async () => {
    await fetchRepos()
    setIsACOpen(true)
  }

  const handleClose = () => setIsACOpen(false)

  if (!scmProvider) {
    return null
  }

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          Select a repo
          {ScmProviders[scmProvider?.provider]?.icon}
        </Box>
      </DialogTitle>

      <DialogContent>
        <Autocomplete
          fullWidth
          autoSelect
          autoComplete
          autoHighlight
          openOnFocus
          selectOnFocus
          handleHomeEndKeys
          value={selectedOption}
          options={options}
          loading={isLoading}
          open={isACOpen}
          onOpen={handleOpen}
          onClose={handleClose}
          groupBy={(option) => option.owner.login}
          onChange={(_, value) => setSelectedOption(value)}
          getOptionLabel={(option) => option.fullName}
          getOptionSelected={(option, selected) => option.id === selected.id}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Repository"
              variant="outlined"
              inputProps={{
                ...params.inputProps,
                // disable autocomplete and autofill
                autoComplete: 'new-password',
              }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
              helperText="You can only see repos that Unreal Cloud has been authorized to access"
            />
          )}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <Button color="primary" disabled={!selectedOption} onClick={() => onSelect(selectedOption)}>
          Select
        </Button>
      </DialogActions>
    </Dialog>
  )
}

RepoSelection.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  repository: PropTypes.object,
  scmProvider: PropTypes.object,
}

RepoSelection.defaultProps = {
  repository: {},
  scmProvider: null,
}

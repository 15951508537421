import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { BasePlan } from './BasePlan'
import { PlanPropType, SubscriptionItemPropType } from '../proptypes'

const useStyles = makeStyles(({ spacing }) => ({
  button: {
    marginRight: spacing(1),
  },
  current: {
    borderWidth: 2,
    fontWeight: 'bold',
    '&:hover': {
      borderWidth: 2,
    },
    '&:disabled': {
      borderWidth: 2,
    },
  },
}))

export const Plan = (props) => {
  const { plan, subscriptionItem, selected, onChange } = props

  const classes = useStyles()

  const onSelect = () => onChange(plan.id)

  return (
    <BasePlan
      plan={plan}
      currentLabel={subscriptionItem ? 'Current' : null}
      actions={
        <Button
          size="medium"
          color="primary"
          variant="outlined"
          onClick={onSelect}
          disabled={selected}
          className={clsx(classes.button, { [classes.current]: !!subscriptionItem })}
        >
          Select
        </Button>
      }
    />
  )
}

Plan.propTypes = {
  onChange: PropTypes.func.isRequired,
  plan: PlanPropType.isRequired,
  selected: PropTypes.bool.isRequired,
  subscriptionItem: SubscriptionItemPropType,
}

Plan.defaultProps = {
  subscriptionItem: null,
}

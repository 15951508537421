import { useMemo } from 'react'
import reduce from 'lodash/reduce'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import { Alert } from '../../components/Alerts'
import { Link } from '../../components/link/Link'
import { Spinner } from '../../components/Reusable'
import { DashboardPage } from '../../components/dashboard-page/DashboardPage'
import { ScmProviders } from '../../constants/ScmProviders'
import { useSCMProviders } from '../../services/scm/scm.queries'
import { ScmProviderCard } from './ScmProviderCard'

export const ScmAccountsPage = () => {
  const { data: scmProviders, isLoading, error } = useSCMProviders()

  const byProvider = useMemo(
    () =>
      reduce(
        scmProviders,
        (acc, scm) => ({
          ...acc,
          [scm.provider]: scm,
        }),
        {},
      ),
    [scmProviders],
  )

  return (
    <DashboardPage title={<Link to="/" label="Overview" />}>
      {isLoading && <Spinner isLoading={isLoading} />}

      <Alert show={!!error} message={error?.message} severity="error" />

      <Card>
        <CardHeader
          title="SCM Providers"
          subheader="Authorize your SCM accounts to be able to connect them to your projects."
        />

        <CardContent>
          <Spinner isLoading={isLoading} />
          <Alert show={!!error} message={error} severity="error" />

          <ScmProviderCard scmProvider={byProvider.github} provider={ScmProviders.github.key} />
        </CardContent>
      </Card>
    </DashboardPage>
  )
}

import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import { PaymentElement } from '@stripe/react-stripe-js'
import { useNotifications } from '../../../hooks/useNotifications'
import {
  useConfirmSetupIntent,
  useOrganizationPaymentMethods,
} from '../../../services/billing/billing.queries'
import { appName } from '../../../config'

export const CreatePaymentMethodContent = (props) => {
  const { organizationId, onSuccess, onFailure, onCancel } = props

  const { showSuccess, showError } = useNotifications()
  const { refetch: refetchCards } = useOrganizationPaymentMethods(organizationId, {
    enabled: false,
  })
  const { isLoading, mutateAsync } = useConfirmSetupIntent({
    onSettled: async (data) => {
      if (data.setupIntent) {
        showSuccess('Successfully created payment method')
        await refetchCards()
        onSuccess(data)
      } else {
        showError(data?.error?.message || 'Error creating payment method. Please try again.')
        onFailure(data)
      }
    },
  })

  const handleOnAddCard = async (event) => {
    event.preventDefault()
    await mutateAsync()
  }

  return (
    <>
      <DialogContent>
        <PaymentElement
          options={{
            business: {
              name: appName,
            },
          }}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={onCancel} disabled={isLoading}>
          Cancel
        </Button>

        <Button variant="contained" color="primary" onClick={handleOnAddCard} disabled={isLoading}>
          {isLoading ? 'Saving' : 'Save'}
        </Button>
      </DialogActions>
    </>
  )
}

CreatePaymentMethodContent.propTypes = {
  organizationId: PropTypes.string.isRequired,
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
  onCancel: PropTypes.func,
}

CreatePaymentMethodContent.defaultProps = {
  onSuccess: () => {},
  onFailure: () => {},
  onCancel: () => {},
}

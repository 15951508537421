import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useElements, useStripe } from '@stripe/react-stripe-js'
import {
  cancelOrganizationSubscription,
  createOrganizationSubscription,
  deleteOrganizationPaymentMethod,
  getOrganizationSubscription,
  getPlans,
  getSetupIntent,
  listOrganizationInvoices,
  listOrganizationPaymentMethods,
  setOrganizationDefaultPaymentMethod,
  updateOrganizationSubscription,
} from './billing.io'

export const queryIds = {
  usePlans: () => ['billing', 'plans'],
  useSetupIntent: (organizationId) => ['billing', 'organizations', organizationId, 'setup-intent'],
  useOrganizationPaymentMethods: (organizationId) => [
    'billing',
    'organizations',
    organizationId,
    'payment-methods',
  ],
  useOrganizationSubscription: (organizationId) => [
    'billing',
    'organizations',
    organizationId,
    'subscriptions',
  ],
  useOrganizationInvoices: (organizationId) => [
    'billing',
    'organizations',
    organizationId,
    'invoices',
  ],
}

export const usePlans = (options = {}) =>
  useQuery(queryIds.usePlans(), getPlans, {
    ...options,
    select: (plans) => {
      const order = ['Indie', 'Team', 'Business', 'Users', 'Minutes', 'Concurrency']
      return plans.sort((p1, p2) => order.indexOf(p1.name) - order.indexOf(p2.name))
    },
  })

export const useOrganizationPaymentMethods = (organizationId, options = {}) =>
  useQuery(
    queryIds.useOrganizationPaymentMethods(organizationId),
    () => listOrganizationPaymentMethods(organizationId),
    options,
  )

export const useSetOrganizationDefaultPaymentMethod = (organizationId, options = {}) => {
  const queryClient = useQueryClient()

  return useMutation(
    (paymentMethodId) => setOrganizationDefaultPaymentMethod(organizationId, paymentMethodId),
    {
      ...options,
      onSuccess: async (...args) => {
        await queryClient.invalidateQueries({
          queryKey: queryIds.useOrganizationPaymentMethods(organizationId),
        })
        options?.onSuccess?.(...args)
      },
    },
  )
}

export const useDeleteOrganizationPaymentMethod = (organizationId, options = {}) => {
  const queryClient = useQueryClient()

  return useMutation(
    (paymentMethodId) => deleteOrganizationPaymentMethod(organizationId, paymentMethodId),
    {
      ...options,
      onSuccess: async (...args) => {
        await queryClient.invalidateQueries({
          queryKey: queryIds.useOrganizationPaymentMethods(organizationId),
        })
        options?.onSuccess?.(...args)
      },
    },
  )
}

export const useSetupIntent = (organizationId, options = {}) =>
  useQuery(queryIds.useSetupIntent(), () => getSetupIntent(organizationId), options)

export const useConfirmSetupIntent = (options = {}) => {
  const stripe = useStripe()
  const elements = useElements()

  return useMutation(() => stripe.confirmSetup({ elements, redirect: 'if_required' }), options)
}

export const useOrganizationSubscription = (organizationId, options = {}) =>
  useQuery(
    queryIds.useOrganizationSubscription(organizationId),
    () => getOrganizationSubscription(organizationId),
    options,
  )

export const useCreateOrganizationSubscription = (organizationId, options = {}) => {
  const queryClient = useQueryClient()

  return useMutation(
    (subscriptionRequest) => createOrganizationSubscription(organizationId, subscriptionRequest),
    {
      ...options,
      onSuccess: async (...args) => {
        await queryClient.invalidateQueries({
          queryKey: queryIds.useOrganizationSubscription(organizationId),
        })
        options?.onSuccess?.(...args)
      },
    },
  )
}

export const useUpdateOrganizationSubscription = (organizationId, subscriptionId, options = {}) => {
  const queryClient = useQueryClient()

  return useMutation(
    (subscriptionRequest) =>
      updateOrganizationSubscription(organizationId, subscriptionId, subscriptionRequest),
    {
      ...options,
      onSuccess: async (...args) => {
        await queryClient.invalidateQueries({
          queryKey: queryIds.useOrganizationSubscription(organizationId),
        })
        options?.onSuccess?.(...args)
      },
    },
  )
}

export const useCancelOrganizationSubscription = (organizationId, subscriptionId, options = {}) => {
  const queryClient = useQueryClient()

  return useMutation(() => cancelOrganizationSubscription(organizationId, subscriptionId), {
    ...options,
    onSuccess: async (...args) => {
      await queryClient.resetQueries({
        queryKey: queryIds.useOrganizationSubscription(organizationId),
      })
      options?.onSuccess?.(...args)
    },
  })
}
export const useOrganizationInvoices = (organizationId, options = {}) =>
  useQuery(
    queryIds.useOrganizationInvoices(organizationId),
    () => listOrganizationInvoices(organizationId),
    options,
  )

import { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import parseISO from 'date-fns/parseISO'
import sortBy from 'lodash/sortBy'
import List from '@material-ui/core/List'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import { Link } from '../../components/link/Link'
import { Alert } from '../../components/Alerts'
import { Placeholder } from '../../components/placeholder/Placeholder'
import { Spinner } from '../../components/Reusable'
import { DashboardPage } from '../../components/dashboard-page/DashboardPage'
import { useTargets } from '../../services/targets/targets.queries'
import { CreateTargetModal } from './CreateTargetModal'
import { TargetListItem } from './TargetListItem'

import TargetsImage from '../../assets/illustrations/targets.svg'

export const TargetList = (props) => {
  const { organization, project } = props

  const [isCreateModalOpen, setCreateModalOpen] = useState(false)

  const { data: targets, isLoading, error } = useTargets(organization.id, project.id)

  const sortedTargets = useMemo(
    () => sortBy(targets, ({ createdAt }) => -parseISO(createdAt).valueOf()),
    [targets],
  )

  if (isLoading) {
    return <Spinner isLoading={isLoading} />
  }

  return (
    <DashboardPage title={<Link to="/" label="Overview" />}>
      <CreateTargetModal
        organization={organization}
        project={project}
        isOpen={isCreateModalOpen}
        onClose={() => setCreateModalOpen(false)}
      />

      <Card>
        <CardHeader
          title="Targets"
          action={
            <CardActions disableSpacing>
              <Button
                color="primary"
                variant="contained"
                size="small"
                onClick={() => setCreateModalOpen(true)}
              >
                + Add New Target
              </Button>
            </CardActions>
          }
        />

        <CardContent>
          <Alert show={!!error} message={error?.message || error} severity="error" />

          {!sortedTargets.length && (
            <Placeholder
              imageSrc={TargetsImage}
              imageAlt="No Targets"
              title="Add Your First Target"
              description="A target is a preset configuration to build your project for."
            />
          )}

          <List>
            {sortedTargets.map((target) => (
              <TargetListItem
                key={target.id}
                organization={organization}
                project={project}
                target={target}
              />
            ))}
          </List>
        </CardContent>
      </Card>
    </DashboardPage>
  )
}

TargetList.propTypes = {
  organization: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
}

import PropTypes from 'prop-types'
import parseISO from 'date-fns/parseISO'
import formatDistance from 'date-fns/formatDistanceStrict'
import Tooltip from '@material-ui/core/Tooltip'
import TimelapseIcon from '@material-ui/icons/TimelapseOutlined'
import { VerticalAlign } from '../vertical-align/VerticalAlign'
import { isBuildFinished } from '../../utils/index'

export const BuildBillableTime = (props) => {
  const { build, hideIcon } = props

  const billableTime =
    isBuildFinished(build.status) && build.startedAt && build.finishedAt
      ? formatDistance(parseISO(build.finishedAt), parseISO(build.startedAt), {
          addSuffix: false,
          includeSeconds: true,
        })
      : null

  if (!billableTime) {
    return null
  }

  return (
    <VerticalAlign>
      {!hideIcon && (
        <>
          <TimelapseIcon fontSize="inherit" />
          &nbsp;
        </>
      )}

      <Tooltip title="Billable Time: the actual time your build was running and for which you will be billed">
        <span>{billableTime}</span>
      </Tooltip>
    </VerticalAlign>
  )
}

BuildBillableTime.propTypes = {
  build: PropTypes.object.isRequired,
  hideIcon: PropTypes.bool,
}

BuildBillableTime.defaultProps = {
  hideIcon: false,
}

import { appName } from '../../config'

const styles = {
  div: {
    color: '#555',
    width: '100%',
    textAlign: 'center',
  },
}

export const Disclaimer = () => (
  <div style={styles.div}>{appName} is in no way affiliated to Epic Games Inc.</div>
)

import { useEffect, useRef } from 'react'
import propTypes from 'prop-types'
import { createPortal } from 'react-dom'

const useLifetimeObject = (factory) => {
  const ref = useRef(null)

  if (ref.current == null) {
    ref.current = factory()
  }

  return ref.current
}

const serializer = new XMLSerializer()

/**
 * A component that renders its children to a data URI and sets adds it to the document head as a favicon
 * @param {object} props The component parameters
 * @param {string} props.type The type parameter of the link element
 * @param {string | DOMTokenList} props.sizes The sizes parameter of the link element
 * @param {string} props.media The media parameter of the link element
 * @param {import('react').ReactNode} props.children The svg to render into the icon
 * @returns {import('react').ReactNode} The rendered portal.
 */
export const SVGLink = (props) => {
  const { children, media, sizes, type } = props
  const fragment = useLifetimeObject(() => document.createDocumentFragment())

  useEffect(() => {
    const link = document.createElement('link')
    link.rel = 'icon'
    link.type = type
    link.sizes = sizes
    link.media = media

    function updateIcon() {
      const source = serializer.serializeToString(fragment)
      link.href = `data:image/svg+xml;base64,${btoa(source)}`
    }

    const observer = new MutationObserver(updateIcon)

    observer.observe(fragment, {
      attributes: true,
      characterData: true,
      childList: true,
      subtree: true,
    })

    updateIcon()
    document.head.appendChild(link)

    return () => {
      observer.disconnect()
      document.head.removeChild(link)
    }
  }, [fragment, type, sizes, media])

  return createPortal(children, fragment)
}

SVGLink.prototype = {
  children: propTypes.node,
  media: propTypes.string,
  type: propTypes.string,
  size: propTypes.string,
}

SVGLink.defaultProps = {
  children: null,
  media: '',
  type: 'image/svg+xml',
  size: 'any',
}

import { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import { DashboardPage } from '../../components/dashboard-page/DashboardPage'
import { Link } from '../../components/link/Link'
import { useQueryParams } from '../../hooks/useQueryParams'
import { SubscriptionsTab } from './tabs/subscriptions/SubscriptionsTab'
import { PaymentMethodsTab } from './tabs/payment-methods/PaymentMethodsTab'
import { InvoicesTab } from './tabs/invoices/InvoicesTab'

const TabPanel = (props) => {
  const { children, selected } = props

  return (
    <div hidden={!selected}>
      {selected && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  )
}

const tabs = [
  {
    id: 'subscriptions',
    name: 'Subscriptions',
    component: SubscriptionsTab,
  },
  {
    id: 'payment-methods',
    name: 'Payment Methods',
    component: PaymentMethodsTab,
  },
  {
    id: 'invoices',
    name: 'Invoices',
    component: InvoicesTab,
  },
]

export const BillingPage = (props) => {
  const { organization } = props

  const [queryParams, setQueryParams] = useQueryParams()
  const initialTab = useMemo(() => {
    const index = tabs.findIndex((tab) => tab.id === queryParams.tab)
    return index >= 0 ? index : 0
  }, [queryParams])
  const [tabIndex, setTabIndex] = useState(initialTab)

  const handleChange = (event, index) => setTabIndex(index)

  useEffect(() => {
    setQueryParams({ tab: tabs[tabIndex].id })
  }, [tabIndex])

  return (
    <DashboardPage
      title={
        <Link
          to={`/organizations/${organization.id}`}
          icon={<ChevronLeft />}
          label="Organization Page"
        />
      }
    >
      <Tabs value={tabIndex} onChange={handleChange} indicatorColor="primary">
        {tabs.map((tab) => (
          <Tab key={tab.id} label={tab.name} />
        ))}
      </Tabs>

      {tabs.map((tab, index) => {
        const Tab = tab.component

        return (
          <TabPanel key={tab.id} selected={index === tabIndex}>
            <Tab organizationId={organization.id} />
          </TabPanel>
        )
      })}
    </DashboardPage>
  )
}

BillingPage.propTypes = {
  organization: PropTypes.object.isRequired,
}

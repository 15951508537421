import {
  getIdToken as firebaseGetIdToken,
  sendEmailVerification as firebaseSendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  updateEmail as firebaseUpdateEmail,
} from 'firebase/auth'
import { auth } from '../libs/firebase'
import { apiAxios } from '../libs/axios'

export const signUp = ({ firstName, lastName, email, password }) =>
  apiAxios
    .post(`/v1/signup`, { firstName, lastName, email, password })
    .then(() => signIn(email, password))

export const signIn = (email, password) => signInWithEmailAndPassword(auth, email, password)

export const me = () => apiAxios.get(`/v1/user`)

export const updateUser = ({ firstName, lastName }) =>
  apiAxios.put(`/v1/user`, { firstName, lastName })

export const updateEmail = (email) => firebaseUpdateEmail(auth.currentUser, email)

export const sendEmailVerification = () => {
  const actionCodeSettings = { url: window.location.href }
  return firebaseSendEmailVerification(auth.currentUser, actionCodeSettings)
}

export const regenerateApiKey = () => apiAxios.put(`/v1/user/regenerate-api-key`)

export const getIdToken = (forceRefresh) => firebaseGetIdToken(auth.currentUser, forceRefresh)

export const signOut = () => auth.signOut()

export const updatePassword = ({ password }) => apiAxios.put('/v1/user/password', { password })

export const resetPassword = (email) => sendPasswordResetEmail(auth, email)

export const confirmPasswordResetCode = (code, password) =>
  auth.confirmPasswordReset(code, password)

import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { darken, lighten, makeStyles, withStyles } from '@material-ui/core/styles'
import LinearProgress from '@material-ui/core/LinearProgress'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Alert } from '../../components/Alerts'
import { useBuildLog } from '../../services/builds/builds.queries'
import { isBuildFinished } from '../../utils'
import { Log } from './Log'

import '../../terminal.css'

const timeout = { enter: 1000, exit: 500 }

const useStyles = makeStyles(({ palette, typography }) => ({
  expandIcon: {
    color: palette.common.white,
  },
}))

const Accordion = withStyles({
  root: {
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion)

const AccordionSummary = withStyles(({ palette, typography }) => ({
  root: {
    color: palette.common.white,
    backgroundColor: darken(palette.common.black, 0.5),
    fontWeight: typography.fontWeightBold,
    minHeight: 50,
    '&:hover': {
      backgroundColor: lighten(palette.common.black, 0.1),
    },
    '&$expanded': {
      minHeight: 50,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {
    marginBottom: 0,
  },
}))(MuiAccordionSummary)

const AccordionDetails = withStyles({
  root: {
    display: 'block',
    width: '100%',
    padding: 0,
  },
})(MuiAccordionDetails)

export const BuildLog = (props) => {
  const { organization, project, build } = props

  const classes = useStyles()

  const {
    data: buildLog = {},
    isInitialLoading,
    error,
    refetch,
  } = useBuildLog(organization.id, project.id, build.id, {
    enabled: !!build.startedAt,
    keepPreviousData: true,
    refetchInterval: ['checkout', 'building'].includes(build.status) ? 2000 : undefined,
  })

  useEffect(() => {
    if (build.startedAt) {
      refetch()
    }
  }, [build.startedAt, build.status, refetch])

  if (isInitialLoading) {
    return <LinearProgress />
  }

  if (error) {
    return <Alert show={!!error} message={error?.message} severity="error" />
  }

  return (
    <div>
      {!build.startedAt && !isBuildFinished(build.status) && (
        <Log log={'⏳ Please wait while the build starts...'} />
      )}

      {buildLog.checkoutLog && (
        <Accordion TransitionProps={{ timeout }} defaultExpanded square>
          <AccordionSummary expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}>
            Checkout
          </AccordionSummary>
          <AccordionDetails>
            <Log log={buildLog.checkoutLog} />
          </AccordionDetails>
        </Accordion>
      )}

      {buildLog.buildLog && (
        <Accordion TransitionProps={{ timeout }} defaultExpanded square>
          <AccordionSummary expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}>
            Build
          </AccordionSummary>
          <AccordionDetails>
            <Log log={buildLog.buildLog} />
          </AccordionDetails>
        </Accordion>
      )}
    </div>
  )
}

BuildLog.propTypes = {
  build: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
}

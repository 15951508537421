import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import { BuildCreation } from '../../components/build/BuildCreation'
import { BuildDuration } from '../../components/build/BuildDuration'
import { BuildIcon } from '../../components/build/BuildIcon'
import { BuildListItemMenu } from './BuildListItemMenu'
import { useAuth } from '../../hooks/useAuth'
import { isUnrealCloudUser } from '../../utils'
import { BuildListItemBuildkiteIcon } from './BuildListItemBuildkiteIcon'

export const BuildListItem = (props) => {
  const { organization, project, build } = props

  const [user] = useAuth()

  return (
    <ListItem
      button
      component={NavLink}
      to={`/organizations/${organization.id}/projects/${project.id}/builds/${build.id}`}
    >
      <ListItemAvatar>
        <BuildIcon status={build.status} />
      </ListItemAvatar>
      <ListItemText
        primary={`#${build.number} - ${build.target.name}`}
        secondary={
          <>
            <BuildCreation build={build} hideIcon={false} />
            &nbsp;&nbsp;
            <BuildDuration build={build} hideIcon={false} />
          </>
        }
      />
      <ListItemSecondaryAction>
        {isUnrealCloudUser(user) && build.startedAt && (
          <BuildListItemBuildkiteIcon organization={organization} project={project} build={build} />
        )}
        <BuildListItemMenu organization={organization} project={project} build={build} />
      </ListItemSecondaryAction>
    </ListItem>
  )
}

BuildListItem.propTypes = {
  build: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
}

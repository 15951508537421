import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import { Particles } from './Particles'
import { IntroNewsletter } from './IntroNewsletter'
import { Section } from '../../components/section/Section'
import { Disclaimer } from '../../components/footer/Disclaimer'
import { Header } from './Header'

const useStyles = makeStyles(({ palette }) => ({
  section: {
    backgroundColor: palette.common.black,
  },
  title: {
    display: 'inline-block',
    color: palette.common.white,
    textAlign: 'left',
    fontSize: 50,
    fontFamily: 'Gotham Rounded Medium, Helvetica Nueue, sans-serif',
  },
  unrealLink: {
    color: palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      color: palette.primary.light,
    },
  },
  disclaimer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
  },
}))

export const ComingSoon = () => {
  const classes = useStyles()

  return (
    <Section className={classes.section}>
      <Header />
      <Particles />

      <Container maxWidth={'md'}>
        <Grid container>
          <Grid item xs={12} md={6}>
            <h1 className={classes.title}>
              CI/CD for
              <br />
              <a
                className={classes.unrealLink}
                href={'https://www.unrealengine.com/'}
                target={'_blank'}
                rel="noreferrer"
              >
                Unreal Engine
              </a>
            </h1>
          </Grid>

          <Grid item xs={12} md={6}>
            <IntroNewsletter />
          </Grid>
        </Grid>

        <Grid container className={classes.disclaimer}>
          <Disclaimer />
        </Grid>
      </Container>
    </Section>
  )
}

import { useState } from 'react'
import clsx from 'clsx'
import IconButton from '@material-ui/core/IconButton'
import LoopIcon from '@material-ui/icons/Loop'
import { useConfirm } from 'material-ui-confirm'
import { useAnimationStyles } from '../../utils/classes'
import { CopyToClipboard } from '../../components/copy-to-clipboard/CopyToClipboard'
import { useAuth } from '../../hooks/useAuth'
import { useNotifications } from '../../hooks/useNotifications'
import { regenerateApiKey } from '../../services/auth.io'

export const ApiKey = () => {
  const [user, setUser] = useAuth()
  const confirm = useConfirm()
  const animClasses = useAnimationStyles()
  const [isLoading, setIsLoading] = useState(false)
  const { showSuccess, showError } = useNotifications()

  const handleRegenerate = () => {
    confirm({
      description:
        'Are you sure you want to rotate your API Key? Previous API Key will cease to work immediately.',
      dialogProps: { maxWidth: 'xs' },
      confirmationText: 'Rotate',
    })
      .then(() => {
        setIsLoading(true)
        regenerateApiKey()
          .then(setUser)
          .then(() => showSuccess('API Key successfully rotated'))
          .catch(({ message }) => showError(message))
          .finally(() => setIsLoading(false))
      })
      .catch(() => {
        // do nothing
      })
  }

  return (
    <>
      {user.apiKey}
      <CopyToClipboard text={user.apiKey} />
      <IconButton onClick={handleRegenerate} disabled={isLoading}>
        <LoopIcon
          fontSize="small"
          className={clsx({
            [animClasses.spin]: isLoading,
          })}
        />
      </IconButton>
    </>
  )
}

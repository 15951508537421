import { useMemo } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import md5 from 'md5'
import min from 'lodash/min'
import map from 'lodash/map'
import pick from 'lodash/pick'
import head from 'lodash/head'
import trim from 'lodash/trim'
import flow from 'lodash/flow'
import slice from 'lodash/slice'
import values from 'lodash/values'
import { makeStyles } from '@material-ui/core/styles'
import MuiAvatar from '@material-ui/core/Avatar'

const useStyles = makeStyles({
  size: ({ size }) => ({
    width: size,
    height: size,
  }),
})

const toLetters = flow([
  // pick meaningful fields only
  (user) => pick(user, ['firstName', 'lastName', 'email', 'name']),
  // get their values
  (user) => values(user),
  // get rid of falsy fields
  (vals) => vals.filter((v) => v),
  // trim leading whitespaces
  (vals) => map(vals, trim),
  // map to first letter of each value
  (trimmed) => map(trimmed, head),
  // keep at most 2 letters
  (heads) => slice(heads, 0, min([2, heads.length])),
])

export const Avatar = (props) => {
  const { user, size, className, ...rest } = props

  const classes = useStyles({ size })
  const letters = useMemo(() => toLetters(user), [user])
  const gravatarSrc = useMemo(
    () => (user.email ? `https://gravatar.com/avatar/${md5(user.email)}?s=${size}&d=404` : null),
    [user, size],
  )

  return (
    <MuiAvatar src={gravatarSrc} className={clsx(classes.size, className)} {...rest}>
      {letters}
    </MuiAvatar>
  )
}

Avatar.propTypes = {
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
  }),
  className: PropTypes.string,
  size: PropTypes.number,
}

Avatar.defaultProps = {
  className: '',
  size: 50,
}

import { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import { SignupForm } from './Form'
import { Page } from '../../components/Reusable'
import { useAuth } from '../../hooks/useAuth'
import { appName } from '../../config'

const useStyles = makeStyles(({ palette }) => ({
  h1: {
    textAlign: 'center',
    marginBottom: 30,
  },
  col: {
    background: palette.common.white,
    border: `1px solid ${palette.grey[400]}`,
    padding: '50px 30px',
    borderRadius: 10,
  },
  linksContainer: {
    color: palette.common.black,
    textAlign: 'center',
    marginTop: 15,
  },
  link: {
    color: palette.primary.main,
  },
}))

export const SignupPage = () => {
  const [user] = useAuth()
  const classes = useStyles()

  useEffect(() => {
    if (user) {
      window.location.assign('/')
    }
  }, [user])

  return (
    <Page>
      <Container maxWidth={'sm'} className={classes.col}>
        <h1 className={classes.h1}>Signup to {appName}</h1>

        <SignupForm />

        <div className={classes.linksContainer}>
          Already have an account?{' '}
          <a className={classes.link} href={'/login'}>
            Login here
          </a>
        </div>
      </Container>
    </Page>
  )
}

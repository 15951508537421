import { apiAxios } from '../../libs/axios'

export const getPlans = () => apiAxios.get('/v1/billing/plans')

export const getSetupIntent = (organizationId) =>
  apiAxios.get(`/v1/billing/organizations/${organizationId}/setup-intent`)

export const getBillingPortalSession = (organizationId, redirectUrl = window.location.href) =>
  apiAxios.get(
    `/v1/billing/organizations/${organizationId}/portal-session?redirectUrl=${redirectUrl}`,
  )

export const getOrganizationSubscription = (organizationId) =>
  apiAxios.get(`/v1/billing/organizations/${organizationId}/subscriptions`)

export const createOrganizationSubscription = (organizationId, subscriptionRequest) =>
  apiAxios.post(`/v1/billing/organizations/${organizationId}/subscriptions`, subscriptionRequest)

export const updateOrganizationSubscription = (
  organizationId,
  subscriptionId,
  subscriptionRequest,
) =>
  apiAxios.put(
    `/v1/billing/organizations/${organizationId}/subscriptions/${subscriptionId}`,
    subscriptionRequest,
  )

export const cancelOrganizationSubscription = (organizationId, subscriptionId) =>
  apiAxios.delete(`/v1/billing/organizations/${organizationId}/subscriptions/${subscriptionId}`)

export const listOrganizationPaymentMethods = (organizationId) =>
  apiAxios.get(`/v1/billing/organizations/${organizationId}/payment-methods`)

export const setOrganizationDefaultPaymentMethod = (organizationId, paymentMethodId) =>
  apiAxios.patch(
    `/v1/billing/organizations/${organizationId}/payment-methods/${paymentMethodId}/default`,
  )

export const deleteOrganizationPaymentMethod = (organizationId, paymentMethodId) =>
  apiAxios.delete(`/v1/billing/organizations/${organizationId}/payment-methods/${paymentMethodId}`)

export const listOrganizationInvoices = (organizationId) =>
  apiAxios.get(`/v1/billing/organizations/${organizationId}/invoices`)

import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Chip from '@material-ui/core/Chip'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardActions from '@material-ui/core/CardActions'
import Avatar from '@material-ui/core/Avatar'
import { PlanPropType } from '../proptypes'
import { Metadata } from './Metadata'
import { Price } from './Price'
import { VerticalAlign } from '../vertical-align/VerticalAlign'

const useStyles = makeStyles(({ palette, spacing }) => ({
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  highlightedCard: {
    border: `1px solid ${palette.primary.main}`,
    boxShadow: 'none',
  },
  headerCardActions: {
    alignSelf: 'center',
    marginTop: spacing(1),
    marginRight: spacing(1),
  },
  cardActions: {
    padding: spacing(0, 2),
  },
  currentChip: {
    padding: spacing(0.5),
    marginLeft: spacing(1),
    height: 16,
    fontSize: 10,
  },
  grid: {
    alignItems: 'center',
  },
}))

export const BasePlan = (props) => {
  const { plan, actions, currentLabel } = props
  const { name, description, image, currency, price, metadata } = plan

  const classes = useStyles()

  return (
    <Card className={clsx(classes.card, { [classes.highlightedCard]: !!currentLabel })}>
      <CardHeader
        avatar={<Avatar src={image} alt={name} variant="square" />}
        action={
          <CardActions className={classes.headerCardActions}>
            <Price currency={currency} price={price} />
          </CardActions>
        }
        title={
          <VerticalAlign>
            {name}
            {currentLabel && (
              <Chip
                label={currentLabel}
                variant="outlined"
                color="primary"
                size="small"
                className={classes.currentChip}
              />
            )}
          </VerticalAlign>
        }
        subheader={description}
      />

      <CardActions className={classes.cardActions}>
        <Grid container className={classes.grid}>
          <Grid item xs={6}>
            <Metadata metadata={metadata} />
          </Grid>
          <Grid item xs={6}>
            <Box textAlign="right">{actions}</Box>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  )
}

BasePlan.propTypes = {
  plan: PlanPropType.isRequired,
  actions: PropTypes.node,
  currentLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

BasePlan.defaultProps = {
  actions: null,
  currentLabel: null,
}

import { apiAxios } from '../../libs/axios'

export const getProjects = (organizationId) =>
  apiAxios.get(`/v1/organizations/${organizationId}/projects`)

export const getProject = (organizationId, projectId) =>
  apiAxios.get(`/v1/organizations/${organizationId}/projects/${projectId}`)

export const createProject = (organizationId, { name }) =>
  apiAxios.post(`/v1/organizations/${organizationId}/projects`, { name })

export const updateProject = (organizationId, projectId, payload) =>
  apiAxios.put(`/v1/organizations/${organizationId}/projects/${projectId}`, payload)

export const deleteProject = (organizationId, projectId) =>
  apiAxios.delete(`/v1/organizations/${organizationId}/projects/${projectId}`)

import PropTypes from 'prop-types'
import { useOrganizationInvoices } from '../../../../services/billing/billing.queries'
import { ErrorText, Spinner } from '../../../../components/Reusable'
import { InvoicesTable } from '../../../../components/invoice/InvoicesTable'

export const InvoicesTab = (props) => {
  const { organizationId } = props

  const { data, isFetching, isError } = useOrganizationInvoices(organizationId)

  if (isFetching) {
    return <Spinner isLoading />
  }

  if (isError) {
    return <ErrorText>An error has occured, please try again.</ErrorText>
  }

  return <InvoicesTable invoices={data} />
}

InvoicesTab.propTypes = {
  organizationId: PropTypes.string.isRequired,
}

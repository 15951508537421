import PropTypes from 'prop-types'
import { Route, Routes } from 'react-router-dom'

export const Router = (props) => {
  const { routes, ...rest } = props

  return (
    <Routes>
      {routes.map(({ element: Element, ...route }) => (
        <Route key={route.path} element={<Element {...rest} />} {...route} />
      ))}
    </Routes>
  )
}

Router.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
}

import { apiAxios } from '../../libs/axios'

export const createAccessToken = ({ code, provider, installation_id }) =>
  apiAxios.post('/v1/oauth/access-token', { code, provider, installation_id })

export const listRepos = ({ page, per_page } = {}) =>
  apiAxios.get('/v1/scm/github/repos', { params: { page, per_page } })

export const listBranches = (owner, repo, { page, per_page } = {}) =>
  apiAxios.get(`/v1/scm/github/${owner}/${repo}/branches`, {
    params: { page, per_page },
  })

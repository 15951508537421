import { apiAxios } from '../../libs/axios'

export const getTargets = (organizationId, projectId) =>
  apiAxios.get(`/v1/organizations/${organizationId}/projects/${projectId}/targets`)

export const createTarget = (organizationId, projectId, { name, branch, platform, ueVersion }) =>
  apiAxios.post(`/v1/organizations/${organizationId}/projects/${projectId}/targets`, {
    name,
    branch,
    platform,
    ueVersion,
  })

export const updateTarget = (organizationId, projectId, targetId, payload) =>
  apiAxios.put(
    `/v1/organizations/${organizationId}/projects/${projectId}/targets/${targetId}`,
    payload,
  )

export const deleteTarget = (organizationId, projectId, targetId) =>
  apiAxios.delete(`/v1/organizations/${organizationId}/projects/${projectId}/targets/${targetId}`)

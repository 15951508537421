import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Elements } from '@stripe/react-stripe-js'
import { useSetupIntent } from '../../../services/billing/billing.queries'
import { ErrorText, Spinner } from '../../Reusable'
import { stripeClient } from '../../../libs/stripe'
import { CreatePaymentMethodContent } from './CreatePaymentMethodContent'

export const CreatePaymentMethodModal = (props) => {
  const { organizationId, open, onClose } = props
  const { data, isLoading, error } = useSetupIntent(organizationId, {
    cacheTime: 0,
  })

  if (error) {
    return <ErrorText>{error.message}</ErrorText>
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>{'Add Credit Card'}</DialogTitle>

      {isLoading ? (
        <Spinner isLoading />
      ) : (
        <Elements stripe={stripeClient} options={{ clientSecret: data?.clientSecret }}>
          <CreatePaymentMethodContent
            organizationId={organizationId}
            onSuccess={onClose}
            onCancel={onClose}
          />
        </Elements>
      )}
    </Dialog>
  )
}

CreatePaymentMethodModal.propTypes = {
  organizationId: PropTypes.string.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func,
}

CreatePaymentMethodModal.defaultProps = {
  open: false,
  onClose: () => {},
}

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { addQueryData, removeQueryData, updateQueryData } from '../../libs/react-query'
import {
  createOrganization,
  deleteOrganization,
  getOrganization,
  getOrganizationMembers,
  getOrganizationPendingMembers,
  getOrganizations,
  inviteMember,
  removeInvitation,
  removeMember,
  updateMemberRole,
} from './organizations.io'

export const queryIds = {
  useOrganizations: () => ['organizations'],
  useOrganization: (organizationId) => ['organizations', organizationId],
  useOrganizationMembers: (organizationId) => ['organizations', organizationId, 'members'],
  useOrganizationPendingMembers: (organizationId) => [
    'organizations',
    organizationId,
    'pending-members',
  ],
}

export const useOrganizations = (options = {}) =>
  useQuery(queryIds.useOrganizations(), () => getOrganizations(), {
    ...options,
    initialData: options?.initialData || [],
  })

export const useOrganization = (organizationId, options = {}) =>
  useQuery(queryIds.useOrganization(organizationId), () => getOrganization(organizationId), options)

export const useCreateOrganization = (options = {}) =>
  useMutation(({ name }) => createOrganization({ name }), {
    ...options,
    onSuccess: (organization) => {
      addQueryData(queryIds.useOrganizations(), organization)
      options?.onSuccess?.(organization)
    },
  })

export const useDeleteOrganization = (organizationId, options = {}) =>
  useMutation(() => deleteOrganization(organizationId), {
    ...options,
    onSuccess: (organization) => {
      removeQueryData(queryIds.useOrganizations(organizationId), organization)
      options?.onSuccess?.(organization)
    },
  })

export const useOrganizationMembers = (organizationId, options = {}) =>
  useQuery(
    queryIds.useOrganizationMembers(organizationId),
    () => getOrganizationMembers(organizationId),
    {
      ...options,
      initialData: options?.initialData || [],
    },
  )

export const useOrganizationPendingMembers = (organizationId, options = {}) =>
  useQuery(
    queryIds.useOrganizationPendingMembers(organizationId),
    () => getOrganizationPendingMembers(organizationId),
    {
      ...options,
      initialData: options?.initialData || [],
    },
  )

export const useInviteMember = (organizationId, options = {}) => {
  const queryClient = useQueryClient()

  return useMutation(({ email, role }) => inviteMember(organizationId, { email, role }), {
    ...options,
    onSuccess: (...args) => {
      queryClient.invalidateQueries({ queryKey: useOrganization(organizationId) })
      queryClient.invalidateQueries({ queryKey: useOrganizationMembers(organizationId) })
      queryClient.invalidateQueries({ queryKey: useOrganizationPendingMembers(organizationId) })
      options?.onSuccess?.(...args)
    },
  })
}

export const useUpdateMemberRole = (organizationId, memberId, options = {}) =>
  useMutation(({ role }) => updateMemberRole(organizationId, memberId, { role }), {
    ...options,
    onSuccess: (member) => {
      updateQueryData(queryIds.useOrganizationMembers(organizationId), member)
      updateQueryData(queryIds.useOrganizationPendingMembers(organizationId), member)
      options?.onSuccess?.(member)
    },
  })

export const useRemoveMember = (organizationId, options = {}) =>
  useMutation((memberId) => removeMember(organizationId, memberId), {
    ...options,
    onSuccess: (member) => {
      removeQueryData(queryIds.useOrganizationMembers(organizationId), member)
      removeQueryData(queryIds.useOrganizationPendingMembers(organizationId), member)
      options?.onSuccess?.(member)
    },
  })

export const useRemoveInvitation = (organizationId, options = {}) =>
  useMutation((invitationId) => removeInvitation(organizationId, invitationId), {
    ...options,
    onSuccess: (member) => {
      removeQueryData(queryIds.useOrganizationMembers(organizationId), member)
      removeQueryData(queryIds.useOrganizationPendingMembers(organizationId), member)
      options?.onSuccess?.(member)
    },
  })

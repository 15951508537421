import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'

export const Price = (props) => {
  const { price, currency } = props

  return <Typography variant="subtitle1">${price / 100}/month</Typography>
}

Price.propTypes = {
  currency: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
}

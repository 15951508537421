import { useState } from 'react'

export const useOpenable = () => {
  const [anchor, setAnchor] = useState(null)

  const isOpen = Boolean(anchor)

  const open = (event) => setAnchor(event.currentTarget)
  const close = () => setAnchor(null)

  return {
    open,
    close,
    isOpen,
    anchor,
  }
}

import { useQuery } from '@tanstack/react-query'
import { listProviders } from './scm.io'

export const queryIds = {
  useSCMProviders: () => ['scm', 'providers'],
}

export const useSCMProviders = (options = {}) =>
  useQuery(queryIds.useSCMProviders(), () => listProviders(), {
    ...options,
    initialData: options?.initialData || [],
  })

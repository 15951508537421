import PropTypes from 'prop-types'
import { useNavigate, useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Divider from '@material-ui/core/Divider'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import DownloadIcon from '@material-ui/icons/CloudDownloadOutlined'
import { useOpenable } from '../../hooks/useOpenable'
import { useNotifications } from '../../hooks/useNotifications'
import {
  useArtifactSignedUrl,
  useCancelBuild,
  useDeleteBuild,
} from '../../services/builds/builds.queries'
import { isBuildFinished } from '../../utils'

const useStyles = makeStyles(({ palette }) => ({
  cancelOrDeleteButton: {
    color: palette.error.main,
  },
}))

const BuildItemArtifactMenuItem = (props) => {
  const { organization, project, build, artifact } = props

  const { showError } = useNotifications()

  const { refetch: getArtifactSignedUrl } = useArtifactSignedUrl(
    organization.id,
    project.id,
    build.id,
    artifact.name,
    {
      enabled: false,
      onError: ({ message }) => showError(message),
    },
  )

  const handleArtifactDownload = async () => {
    const { data } = await getArtifactSignedUrl({ throwOnError: true })

    const link = document.createElement('a')
    link.href = data.url
    link.download = artifact.name
    link.click()
  }

  return (
    <MenuItem key={artifact.name} onClick={handleArtifactDownload}>
      <DownloadIcon fontSize="small" />
      &nbsp; {artifact.name}
    </MenuItem>
  )
}

BuildItemArtifactMenuItem.propTypes = {
  artifact: PropTypes.object.isRequired,
  build: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
}

export const BuildListItemMenu = (props) => {
  const { organization, project, build } = props

  const classes = useStyles()

  const { buildId } = useParams()
  const navigate = useNavigate()

  const { open, close, isOpen, anchor } = useOpenable()
  const { showSuccess, showError } = useNotifications()

  const { mutateAsync: cancelBuild } = useCancelBuild(organization.id, project.id, build.id, {
    onSuccess: () => showSuccess(`Build #${build.number} successfully canceled`),
    onError: ({ message }) => showError(message),
  })

  const { mutateAsync: deleteBuild } = useDeleteBuild(organization.id, project.id, build.id, {
    onSuccess: () => showSuccess(`Build #${build.number} successfully deleted`),
    onError: ({ message }) => showError(message),
  })

  const buildFinished = isBuildFinished(build.status)

  const handleCancel = async () => {
    await cancelBuild()
    close()
  }

  const handleDelete = async () => {
    await deleteBuild()

    // if we're on a build details page, navigate back to build list
    if (buildId) {
      navigate(`/organizations/${organization.id}/projects/${project.id}/builds`, { replace: true })
    }
  }

  return (
    <>
      <IconButton onClick={open}>
        <MoreVertIcon />
      </IconButton>

      <Menu anchorEl={anchor} open={isOpen} onClose={close}>
        {(build.artifacts || []).map((artifact) => (
          <BuildItemArtifactMenuItem
            organization={organization}
            project={project}
            build={build}
            artifact={artifact}
          />
        ))}

        {Boolean(build.artifacts?.length) && <Divider />}

        {!buildFinished && (
          <MenuItem className={classes.cancelOrDeleteButton} onClick={handleCancel}>
            Cancel
          </MenuItem>
        )}

        {buildFinished && (
          <MenuItem className={classes.cancelOrDeleteButton} onClick={handleDelete}>
            Delete
          </MenuItem>
        )}
      </Menu>
    </>
  )
}

BuildListItemMenu.propTypes = {
  build: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
}

import { useState, useEffect } from 'react'

export const useInterval = (milliseconds = 1000) => {
  const [ticks, setTicks] = useState(0)

  useEffect(() => {
    const idx = setInterval(() => setTicks((ticks) => ticks + 1), milliseconds)

    return () => clearInterval(idx)
  }, [milliseconds])

  return ticks
}

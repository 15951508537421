export const Platforms = [
  {
    name: 'Android',
    value: 'android',
    enabled: true,
  },
  {
    name: 'AR',
    value: 'ar',
    enabled: true,
  },
  {
    name: 'VR',
    value: 'vr',
    enabled: true,
  },
  {
    name: 'HTML5',
    value: 'html5',
    enabled: true,
  },
  {
    name: 'Linux',
    value: 'linux',
    enabled: true,
  },
  {
    name: 'Windows 32-bit',
    value: 'win32',
    enabled: true,
  },
  {
    name: 'Windows 64-bit',
    value: 'win64',
    enabled: true,
  },
  {
    name: 'iOS (coming soon)',
    value: 'ios',
    enabled: false,
  },
  {
    name: 'MacOS (coming soon)',
    value: 'macos',
    enabled: false,
  },
  {
    name: 'Google Stadia (coming soon)',
    value: 'google_stadia',
    enabled: false,
  },
  {
    name: 'PlayStation 4 (coming soon)',
    value: 'playstation_4',
    enabled: false,
  },
  {
    name: 'PlayStation 5 (coming soon)',
    value: 'playstation_5',
    enabled: false,
  },
  {
    name: 'Xbox Series X (coming soon)',
    value: 'xbox_series_x',
    enabled: false,
  },
  {
    name: 'Xbox One (coming soon)',
    value: 'xbox_one',
    enabled: false,
  },
  {
    name: 'Nintendo Switch (coming soon)',
    value: 'nintendo_switch',
    enabled: false,
  },
]

import PropTypes from 'prop-types'
import orderBy from 'lodash/orderBy'
import { useConfirm } from 'material-ui-confirm'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/DeleteOutlined'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import { useNotifications } from '../../hooks/useNotifications'
import { useAuth } from '../../hooks/useAuth'
import {
  useRemoveInvitation,
  useRemoveMember,
} from '../../services/organizations/organizations.queries'
import { Avatar } from '../avatar/Avatar'
import { RoleSelect } from './RoleSelect'

const useStyles = makeStyles(({ palette, spacing }) => ({
  avatar: {
    backgroundColor: palette.secondary.main,
  },
  adminChip: {
    marginLeft: spacing(1),
  },
  deleteButton: {
    color: palette.error.main,
  },
  deleteButtonIcon: {
    '&:hover': {
      color: palette.error.main,
    },
  },
}))

export const MemberList = (props) => {
  const { members, organizationId, isOrgAdmin } = props

  const classes = useStyles()
  const [user] = useAuth()
  const confirm = useConfirm()
  const { showSuccess, showError } = useNotifications()
  const { mutateAsync: removeMember } = useRemoveMember(organizationId, {
    onSuccess: () => showSuccess('Member successfully deleted'),
    onError: ({ message }) => showError(message),
  })
  const { mutateAsync: removeInvitation } = useRemoveInvitation(organizationId, {
    onSuccess: () => showSuccess('Invitation successfully deleted'),
    onError: ({ message }) => showError(message),
  })

  const isOwnMember = (member) => user.id === member.id

  const handleDelete = (member) => {
    const deleteFunc = member.hasAccepted ? removeMember : removeInvitation

    confirm({
      description: 'This action is permanent!',
      dialogProps: { maxWidth: 'xs' },
      confirmationText: 'Delete',
      confirmationButtonProps: { className: classes.deleteButton },
    })
      .then(async () => await deleteFunc(member.id))
      .catch(() => {
        // do nothing
      })
  }

  return (
    <List>
      {orderBy(members, ['firstName', 'email'], 'asc').map((member) => (
        <ListItem key={member.id}>
          <ListItemAvatar>
            <Avatar user={member} />
          </ListItemAvatar>

          <ListItemText
            primary={
              <>
                {
                  // show email if invitation pending
                  member.hasAccepted ? (
                    <>
                      {member.firstName} {member.lastName}
                    </>
                  ) : (
                    <>{member.email}</>
                  )
                }
              </>
            }
            secondary={member.hasAccepted ? member.email : 'Pending Invitation'}
          />

          <ListItemSecondaryAction>
            <RoleSelect
              isOrgAdmin={isOrgAdmin}
              member={member}
              organizationId={organizationId}
              disabled={!isOrgAdmin || !member.hasAccepted || isOwnMember(member)}
            />

            <IconButton
              className={classes.deleteButtonIcon}
              onClick={() => handleDelete(member)}
              disabled={!isOrgAdmin || isOwnMember(member)}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
  )
}

MemberList.propTypes = {
  isOrgAdmin: PropTypes.bool.isRequired,
  members: PropTypes.arrayOf(PropTypes.object).isRequired,
  organizationId: PropTypes.string.isRequired,
}

import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: spacing(6),
  },
  image: {
    height: 200,
  },
  content: {
    maxWidth: 400,
    marginLeft: spacing(6),
  },
}))

export const Placeholder = (props) => {
  const { imageAlt, imageSrc, ImageProps, title, TitleProps, description } = props
  const classes = useStyles()

  return (
    <div className={classes.root}>
      {imageSrc && <img src={imageSrc} alt={imageAlt} className={classes.image} {...ImageProps} />}

      {(title || description) && (
        <div className={classes.content}>
          {title && (
            <Typography variant="h4" gutterBottom {...TitleProps}>
              {title}
            </Typography>
          )}

          {description && (
            <Typography variant="body1" component="div">
              {description}
            </Typography>
          )}
        </div>
      )}
    </div>
  )
}

Placeholder.propTypes = {
  imageAlt: PropTypes.string,
  imageSrc: PropTypes.node,
  ImageProps: PropTypes.object,
  title: PropTypes.string,
  TitleProps: PropTypes.object,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

Placeholder.defaultProps = {
  imageAlt: '',
  imageSrc: null,
  ImageProps: {},
  title: '',
  TitleProps: {},
  description: '',
}

import ReactParticles from 'react-tsparticles'
import { loadFull } from 'tsparticles'

const particlesConfig = {
  fps_limit: 60,
  particles: {
    collisions: {
      enable: false,
    },
    number: {
      value: 400,
      density: {
        enable: false,
      },
    },
    line_linked: {
      enable: true,
      distance: 30,
      opacity: 0.4,
    },
    move: {
      speed: 1,
    },
    opacity: {
      anim: {
        enable: true,
        opacity_min: 0.05,
        speed: 1,
        sync: false,
      },
      value: 0.5,
    },
  },
  polygon: {
    enable: true,
    scale: 0.7,
    type: 'inline',
    move: {
      radius: 3,
    },
    url: '/particles/unreal-engine.svg',
    inline: {
      arrangement: 'equidistant',
    },
    draw: {
      enable: true,
      stroke: {
        color: 'rgba(255, 255, 255, .2)',
      },
    },
  },
  retina_detect: false,
  interactivity: {
    events: {
      onhover: {
        enable: true,
        mode: 'bubble',
      },
    },
    modes: {
      bubble: {
        size: 10,
        distance: 40,
      },
    },
  },
}

export const Particles = () => <ReactParticles init={loadFull} params={particlesConfig} />

import PropTypes from 'prop-types'
import { Controller, useForm } from 'react-hook-form'
import upperFirst from 'lodash/upperFirst'
import { yupResolver } from '@hookform/resolvers/yup'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputLabel from '@material-ui/core/InputLabel'
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { useInviteMember } from '../../services/organizations/organizations.queries'
import { useNotifications } from '../../hooks/useNotifications'
import { ErrorText } from '../../components/Reusable'
import { inviteMemberSchema } from '../../components/validations'

export const AddMemberModal = (props) => {
  const { organizationId, isOpen, onClose } = props

  const { mutateAsync } = useInviteMember(organizationId, {
    onSuccess: () => showSuccess(`${email} successfully invited`),
    onError: ({ message }) => showError(message),
  })

  const { showSuccess, showError } = useNotifications()
  const {
    control,
    register,
    handleSubmit,
    formState: { isSubmitting, isValid, errors },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(inviteMemberSchema),
    shouldFocusError: false,
    defaultValues: {
      email: '',
      role: '',
    },
  })

  const submit = ({ email, role }) => mutateAsync({ email, role })

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth>
      <DialogTitle>{'Invite Member to Org'}</DialogTitle>

      <DialogContent>
        <TextField
          {...register('email')}
          name="email"
          label="Email"
          variant="outlined"
          fullWidth
          autoFocus
        />
        <ErrorText>{errors.email?.message}</ErrorText>

        <br />

        <Controller
          name="role"
          control={control}
          defaultValue=""
          rules={{ required: 'Required' }}
          render={({ field }) => (
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="role-id-label">Role</InputLabel>
              <Select {...field} id="roleId" labelId="role-id-label" label="Role">
                {['user', 'admin'].map((role) => (
                  <MenuItem key={role} value={role}>
                    {upperFirst(role)}
                  </MenuItem>
                ))}
              </Select>
              <ErrorText>{errors.role?.message}</ErrorText>
            </FormControl>
          )}
        />

        <FormHelperText>
          A user with an existing account will be added to the org immediately. Otherwise, his
          invitation will be pending until he creates an account.
        </FormHelperText>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={isSubmitting || !isValid}
          onClick={handleSubmit(submit)}
        >
          {isSubmitting ? 'Inviting...' : 'Invite'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

AddMemberModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  organizationId: PropTypes.string.isRequired,
}

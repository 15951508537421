import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'

const useStyles = makeStyles(({ spacing }) => ({
  alert: {
    marginBottom: spacing(2),
  },
}))

export const NoPaymentMethodWarning = (props) => {
  const { paymentMethods } = props

  const classes = useStyles()

  if (paymentMethods.length) {
    return null
  }

  return (
    <Alert severity="info" className={classes.alert}>
      <AlertTitle>No payment methods</AlertTitle>
      You need to add a payment method before subscribing. Click on "Payment Method" tab above to
      continue
    </Alert>
  )
}

NoPaymentMethodWarning.propTypes = {
  paymentMethods: PropTypes.array.isRequired,
}

NoPaymentMethodWarning.defaultProps = {
  paymentMethods: [],
}

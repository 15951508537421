import { Colors } from '../../constants/Colors'

const styles = {
  title: {
    color: Colors.blue.main,
    fontSize: 'xx-large',
    fontWeight: 'bold',
    fontFamily: 'Gotham Rounded Light, Helvetica Nueue, sans-serif',
  },
  subtitle: {
    color: Colors.dark.main,
    fontSize: 'x-large',
    marginTop: 15,
    fontFamily: 'Gotham Rounded Light',
  },
  section: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
  },
  button: {
    color: 'white',
    backgroundColor: Colors.blue.light,
    borderColor: Colors.blue.main,
  },
}

export const Section = ({ id, children, ...props }) => (
  <div id={id} style={{ ...styles.section }} {...props}>
    {children}
  </div>
)

import { useMemo } from 'react'
import PropTypes from 'prop-types'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import FormHelperText from '@material-ui/core/FormHelperText'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import sortBy from 'lodash/sortBy'
import * as Yup from 'yup'
import { Alert } from '../../components/Alerts'
import { useNotifications } from '../../hooks/useNotifications'
import { ErrorText } from '../../components/Reusable'
import { useCreateBuild } from '../../services/builds/builds.queries'
import { useTargets } from '../../services/targets/targets.queries'

const schema = Yup.object({
  targetId: Yup.string().required().label('Target'),
})

export const CreateBuildModal = (props) => {
  const { organizationId, projectId, isOpen, onClose } = props

  const { showSuccess, showError } = useNotifications()
  const { data: targets, isLoading, error } = useTargets(organizationId, projectId)
  const { mutateAsync: createBuild } = useCreateBuild(organizationId, projectId, {
    onSuccess: () => showSuccess('Build successfully launched'),
    onError: ({ message }) => showError(message),
  })

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isValid, errors },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    shouldFocusError: false,
    defaultValues: {
      targetId: '',
    },
  })

  const sortedTargets = useMemo(() => sortBy(targets, 'name'), [targets])

  const submit = async ({ targetId }) => {
    await createBuild({ targetId })
    onClose()
  }

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth>
      <DialogTitle>{'Trigger Build'}</DialogTitle>

      <DialogContent>
        <Alert show={!!error} message={error?.message || error} severity="error" />

        <Controller
          name="targetId"
          control={control}
          defaultValue=""
          rules={{ required: 'Required' }}
          render={({ field }) => (
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="target-id-label">Target</InputLabel>
              <Select
                {...field}
                id="targetId"
                labelId="target-id-label"
                label="Target"
                disabled={isLoading}
              >
                {sortedTargets.map(({ id, name }) => (
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>The target for which to build the project</FormHelperText>
              <ErrorText>{errors.targetId?.message}</ErrorText>
            </FormControl>
          )}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={isLoading || isSubmitting || !isValid}
          onClick={handleSubmit(submit)}
        >
          {isSubmitting ? 'Launching...' : 'Launch'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

CreateBuildModal.propTypes = {
  organizationId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

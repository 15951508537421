import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import TextField from '@material-ui/core/TextField'
import { signupSchema } from '../../components/validations'
import { ErrorText, SubmitButton } from '../../components/Reusable'
import { useAuth } from '../../hooks/useAuth'
import { useNotifications } from '../../hooks/useNotifications'
import { me, signOut, signUp } from '../../services/auth.io'

export const SignupForm = () => {
  const [, setUser] = useAuth()
  const { showSuccess, showError } = useNotifications()
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, isValid, errors },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(signupSchema),
    shouldFocusError: false,
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      passwordConfirm: '',
    },
  })

  const submit = (payload) =>
    signUp(payload)
      .then(me)
      .then(setUser)
      .then(() => showSuccess('Welcome to Unreal Cloud!'))
      .catch(({ message }) => {
        showError(message)
        return signOut()
      })

  return (
    <>
      <TextField
        {...register('firstName')}
        name="firstName"
        label="First Name"
        variant="outlined"
        fullWidth
        autoFocus
      />
      <ErrorText>{errors.firstName?.message}</ErrorText>

      <br />

      <TextField
        {...register('lastName')}
        name="lastName"
        label="Last Name"
        variant="outlined"
        fullWidth
      />
      <ErrorText>{errors.lastName?.message}</ErrorText>

      <br />

      <TextField
        {...register('email')}
        name="email"
        type="email"
        label="Email"
        variant="outlined"
        fullWidth
      />
      <ErrorText>{errors.email?.message}</ErrorText>

      <br />

      <TextField
        {...register('password')}
        name="password"
        type="password"
        label="Password"
        variant="outlined"
        fullWidth
      />
      <ErrorText>{errors.password?.message}</ErrorText>

      <br />

      <TextField
        {...register('passwordConfirm')}
        name="passwordConfirm"
        type="password"
        label="Confirm Password"
        variant="outlined"
        fullWidth
      />
      <ErrorText>{errors.passwordConfirm?.message}</ErrorText>

      <br />

      <SubmitButton
        type="submit"
        disabled={!isValid || isSubmitting}
        onClick={handleSubmit(submit)}
        fullWidth
      >
        {isSubmitting ? 'Loading...' : 'Signup'}
      </SubmitButton>
    </>
  )
}
